import '/services/util.js'

const tagName = "datenschutz-page";

var pageTitle = "Datenschutz | Franklin Institute of Applied Sciences";
var pageDescription = "Datenschutzerklärung des Franklin Institute of Applied Sciences";

var css = /*css*/`
    // ${tagName} h3 {
    //     line-height: 1.2em;
    // }
`;

var template = /*html*/`
    <div class="wrapper" id="datenschutz-cont">
        <div style="height: 1000px;"></div>
    </div>
    <style>${css}</style>
`;

class Page extends HTMLElement {
    constructor(){
        super();
    }

    connectedCallback(){
        util.setPageTitle(pageTitle);
        util.setPageDescription(pageDescription);
        this.innerHTML = template;
        this.innerCont = this.querySelector('#datenschutz-cont');
        this.render();
    }

    async render(){
        var response = await fetch("/assets/text/fias-datenschutz.html");
        var responseText = await response.text();
        this.innerCont.innerHTML = responseText;
        var internalLinks = Array.from(this.querySelectorAll('a.index-link'));
        internalLinks.forEach(link=>{
            link.setAttribute('internal',"");
            var preHref = link.getAttribute('href');
            link.setAttribute('href', location.pathname + preHref);
        });
    }
}

customElements.define(tagName, Page);

export default `<${tagName}></${tagName}>`