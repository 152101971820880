import HeroButton from '/components/hero-button.js'
import FeatureCard from '/components/feature-card.js'
import '/config.js'

const tagName = "product-hero-section";

const heroData = {
    pm: {
        text: /*html*/`IT-Berater im<br>Produktmanagement`,
        img: "/assets/images-webp/product-hero-img-pm.webp"
    },
    sales: {
        text: /*html*/`IT-Sales Manager &<br>Solution Architect`,
        img: "/assets/images-webp/product-hero-img-sales.webp"
    },
};

var topSectionCSS = /*css*/`
    ${tagName} .product-hero-main-cont {
        display: flex;
    }
    ${tagName} .product-hero-content-title {
        font-size: clamp(1.5em, 7.1vw, 2.5em);
        font-family: 'inter-bold';
        letter-spacing: -0.01em;
        line-height: 1.1em;
    }
    ${tagName} .product-hero-content-title span {
        text-decoration: underline;
        text-decoration-color: var(--blue-brand);
    }
    ${tagName} .product-hero-content-cont {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 1.3em;
        flex: 1;
    }
    ${tagName} .product-hero-visual-cont {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    ${tagName} .product-hero-img {
        aspect-ratio: 2/3;
        width: 100%;
        max-width: 340px;
        margin: auto;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 11px;
        position: relative;
        overflow: hidden;
    }
    ${tagName} .hero-img-partner-cont {
        position: absolute;
        bottom: 0;
        width: 100%;
        display: flex;
        padding: 0.8em;
        gap: 0.8em;
        box-sizing: border-box;
        background-image: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.5));
        background-size: 100% 100%;
    }
    ${tagName} .hero-img-partner {
        background: white;
        border-radius: 7px;
        flex: 1;
        padding: 8px 12px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 4px;
        transition: transform 200ms;
    }
    ${tagName} .hero-img-partner:hover {
        transform: translateY(-3px);
    }
    ${tagName} .hero-img-partner span {
        font-size: 0.6rem;
        text-align: center;
    }
    ${tagName} .hero-img-partner-logo {
        display: flex;
        width: 100%;
        aspect-ratio: 55 / 18;
        background-position: 50% 50%;
        background-size: contain;
        background-repeat: no-repeat;
    }
    ${tagName} .hero-img-partner-logo-ihk {
        background-image: url('/assets/images/ihk-akademie-muenchen-oberbayern.svg');
    }
    ${tagName} .hero-img-partner-logo-ixdf {
        background-image: url('/assets/images/ixdf-logo-black-medium.png');
    }
    ${tagName} .product-hero-content-bulletpoints {
        display: flex;
        flex-direction: column;
        gap: 0.2em;
    }
    ${tagName} .product-hero-content-bulletpoint:before {
        content: '';
        display: inline-block;
        width: 1em;
        height: 1em;
        background-position: 50% 50%;
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url('/assets/images/blue-arrow-double.svg');
        transform: rotate(-90deg) translateX(-0.15em);
        margin-right: 0.5em;
    }
    ${tagName} .section-kursnet-link {
        transition: 200ms;
    }
    ${tagName} .section-kursnet-link:hover{
        color: var(--blue-brand);
    }
    ${tagName} .section-kursnet-link:before{
        content: "";
        display: inline-flex;
        width: 20px;
        aspect-ratio: 1 / 1;
        background-image: url('/assets/images/afa-logo.svg');
        background-size: contain;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        margin-right: 8px;
        transform: translateY(5px);
    }
    @media(max-width: 970px){
        ${tagName} .product-hero-main-cont {
            gap: 3em;
        }
        ${tagName} .product-hero-content-cont {
            flex: 1.5;
        }
        ${tagName} .product-hero-visual-cont {
            flex: 1;
        }
    }
    @media(max-width: 840px){
        ${tagName} {
            padding-top: 1rem;
        }
        ${tagName} .product-hero-main-cont {
            flex-direction: column;
            max-width: 550px;
            margin: auto;
        }
        ${tagName} .product-hero-content-title {
            text-align: center;
            margin-bottom: 0.5em;
        }
        ${tagName} .product-hero-content-cont {
            flex: 1.5;
        }
        ${tagName} .product-hero-visual-cont {
            flex: 1;
        }
    }
    @media(max-width: 470px){
        ${tagName} .product-hero-content-bulletpoints {
            gap: 0.5em;
        }
    }
    @media(max-width: 360px){
        ${tagName} .product-hero-main-cont {
            gap: 1.2em;
        }
    }
`;

var featureCardCSS = /*css*/`
    ${tagName} .feature-card-cont {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        margin-top: 45px;
        gap: 45px;
    }
    @media (max-width: 999px) {
        ${tagName} .feature-card-cont {
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 1fr 1fr;
        }
        ${tagName} .feature-card-cont>*:nth-child(2){
            grid-column: 1 / 3;
        }
    
        ${tagName} .feature-card-cont>*:nth-child(1){
            grid-row: 2;
        }
    }
    @media(max-width: 599px){
        ${tagName} .feature-card-cont {
            grid-template-columns: 1fr;
            grid-template-rows: 1fr 1fr 1fr;
            gap: 20px;
        }
    
        ${tagName} .feature-card-cont>*:nth-child(2){
            grid-column: initial;
        }
    
        ${tagName} .feature-card-cont>*:nth-child(1){
            grid-row: initial;
        }
    }
    @media(max-width: 360px){
        ${tagName} .feature-card-cont {
            margin-top: 20px;
        }
    }
`;

var css = /*css*/`
    ${tagName} {
        display: block;
        padding-top: 2em;
        padding-bottom: 2rem;
        font-family: 'inter-medium';
    }
    ${topSectionCSS}
    ${featureCardCSS}
`;

const renderInnerHTML = (options)=>{
    var slug = options.courseSlug;
    return /*html*/`
        <div class="product-hero-main-cont">
            <div class="product-hero-content-cont">
                <div class="product-hero-content-title">Sichere Dir jetzt Deine<br>Online-Weiterbildung zum<br><span>${heroData[slug].text}</span></div>
                <div class="product-hero-content-text">
                    <b>In ${config.courseData[slug].duration} Monaten aus der Arbeitslosigkeit zum Traumjob.</b>
                </div>
                <div class="product-hero-content-bulletpoints">
                    <div class="product-hero-content-bulletpoint">100% Kostenlose Weiterbildung für Arbeitssuchende ✅</div>
                    <div class="product-hero-content-bulletpoint">Kostenloses MacBook inklusive 💻</div>
                    <div class="product-hero-content-bulletpoint">Bequem von zu Hause aus lernen 🏠</div>
                    <div class="product-hero-content-bulletpoint">Über ${config.courseData[slug].minCertCount} angesehene Zertifikate + IHK Zertifikat 🎓</div>
                    <div class="product-hero-content-bulletpoint">Optimal für Quereinsteiger geeignet 📈</div>
                </div>
                <a class="section-kursnet-link product-hero-content-text" 
                ${/* slug == "sales" ? ' style="display: none;"' : '' */ ''}
                target="_blank" href="https://web.arbeitsagentur.de/weiterbildungssuche/angebot/${config.courseData[slug].kursnetID}">
                Unsere Maßnahme in Kursnet
            </a>
            <a cta-prevent-inner-hover target="_blank" href="${config.ctaURLS[slug]}">${HeroButton(/*html*/`Kostenlose Beratung vereinbaren und <span>durchstarten.</span>`)}</a>
            </div>
            <div class="product-hero-visual-cont">
                <div class="product-hero-img-cont">
                    <div class="product-hero-img" style="background-image: url('${heroData[slug].img}')">
                        <div class="hero-img-partner-cont">
                            <a target="_blank" href="https://www.ihk-akademie-muenchen.de/" class="hero-img-partner">
                                <span>Offizieller Partner von</span>
                                <div class="hero-img-partner-logo hero-img-partner-logo-ihk"></div>
                            </a>
                            <a target="_blank" href="https://www.interaction-design.org/courses" class="hero-img-partner">
                                <span>Offizieller Partner von</span>
                                <div class="hero-img-partner-logo hero-img-partner-logo-ixdf"></div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="feature-card-cont">
            ${FeatureCard(options.featureCards[0])}
            ${FeatureCard(options.featureCards[1])}
            ${FeatureCard(options.featureCards[2])}
        </div>
        <style>${css}</style>
    `;
};

class CustomElem extends HTMLElement {
    constructor(){
        super();
    }

    connectedCallback(){
        var d = window.decodeURIComponent;
        this.options = JSON.parse(d(this.getAttribute('element-options')));
        this.innerHTML = renderInnerHTML(this.options);
        this.style.backgroundImage = `url('${this.options.imageURL}')`;
        this.removeAttribute('element-options');
    }
}

customElements.define(tagName, CustomElem);

function render(options){
    var e = window.encodeURIComponent;
    return `<${tagName} element-options="${e(JSON.stringify(options))}"></${tagName}>`;
}

export default render