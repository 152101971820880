const tagName = "warum-pm-section";

var css = /*css*/`
    ${tagName} {
        display: block;
        font-family: 'inter-medium';
    }
    ${tagName} .grid-outer-cont {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    ${tagName} .section-grid {
        max-width: 1000px;
        display: grid;
        gap: 40px;
        grid-template-columns: 1fr 1fr 1fr;
    }
    ${tagName} .section-grid .section-grid-box {
        padding: 20px 25px;
        border-radius: 20px;
        overflow: hidden;
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
    }
    ${tagName} .section-grid .section-grid-box:nth-child(1) {
        padding: 0;
        grid-column: 1;
        grid-row: 1;
    }
    ${tagName} .section-grid .section-grid-box:nth-child(2) {
        grid-column: 2 / 4;
        grid-row: 1;
        background: var(--light-gray);
        background: #EBEBEB;
    }
    ${tagName} .section-grid .section-grid-box:nth-child(3) {
        color: white;
        grid-column: 1 / 3;
        grid-row: 2;
        background: var(--gray-brand-gradient-light);
    }
    ${tagName} .section-grid .section-grid-box:nth-child(4) {
        grid-column: 3;
        grid-row: 2;
        padding: 0;
    }
    ${tagName} .info-img-1 {
        background-image: url('/assets/images-webp/product-manager-img.webp');
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: 50% 50%;
    }
    ${tagName} .info-img-2 {
        background-image: url('/assets/images-webp/philosophie-img-2.webp');
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: 50% 50%;
    }
    @media(max-width: 899px){
        ${tagName} .section-grid {
            grid-template-columns: 1fr 1fr;
        }
        ${tagName} .section-grid .section-grid-box:nth-child(1) {
            grid-column: 1;
            grid-row: 2;
            aspect-ratio: 1 / 1.5;
            display: none;
        }
        ${tagName} .section-grid .section-grid-box:nth-child(2) {
            grid-column: 1/3;
            grid-row: 1;
        }
        ${tagName} .section-grid .section-grid-box:nth-child(3) {
            grid-column: 1/3;
            grid-row: 3;
        }
        ${tagName} .section-grid .section-grid-box:nth-child(4) {
            grid-column: 1/3;
            grid-row: 2;
            aspect-ratio: 1.5 / 1;
        }
    }
    @media(max-width: 499px){
        ${tagName} .section-grid {
            grid-template-columns: 1fr;
        }
        ${tagName} .section-grid .section-grid-box:nth-child(1) {
            grid-column: 1;
            grid-row: 2;
            aspect-ratio: 1 / 1.3;
            display: initial;
        }
        ${tagName} .section-grid .section-grid-box:nth-child(2) {
            grid-column: 1;
            grid-row: 1;
        }
        ${tagName} .section-grid .section-grid-box:nth-child(3) {
            grid-column: 1;
            grid-row: 3;
        }
        ${tagName} .section-grid .section-grid-box:nth-child(4) {
            grid-column: 1;
            grid-row: 4;
            aspect-ratio: 1.3 / 1;
        }
    }
    ${tagName} .section-bulletpoints {
        display: flex;
        flex-direction: column;
        gap: 0.5em;
    }
    ${tagName} .section-bulletpoint b {
        font-family: 'inter-bold', sans-serif;
    }
    ${tagName} .section-bulletpoint:before {
        margin: 0 !important;
        margin-right: 0.5em !important;
        display: inline-flex;
        content: '';
        min-width: 12px;
        min-height: 12px;
        background-image: url('/assets/images/blue-arrow-double.svg');
        background-size: 100% 100%;
        transform: rotate(-90deg);
    }
    ${tagName} .section-text {
        margin-bottom: 2em;
        line-height: 1.6em;
    }
    ${tagName} .section-text b {
        font-family: 'inter-bold';
    }
    ${tagName} .section-title {
        margin-bottom: 1em;
        font-family: 'inter-bold';
        font-size: 1.2rem;
    }
`;

const renderInnerHTML = (options)=>{
    return /*html*/`
        <div class="grid-outer-cont">
            <div class="section-grid">
                <div class="section-grid-box">
                    <div class="info-img-1"></div>
                </div>
                <div class="section-grid-box">
                    <div class="section-title">IT-Fachkräfte unterstützen Unternehmen bei der Optimierung ihrer digitalen Prozesse und sind in vielen Bereichen gefragt. Es gibt zahlreiche Gründe, warum sich eine Karriere in diesem Bereich lohnt:</div>
                    <div class="section-bulletpoints">
                        <div class="section-bulletpoint">Zukunftssicherheit: Die fortschreitende Digitalisierung prägt alle Branchen und wird auch in Zukunft von zentraler Bedeutung sein. Entwicklungen wie die künstliche Intelligenz verdeutlichen den langfristigen Bedarf an IT-Expertise.</div>
                        <div class="section-bulletpoint">Vielfältige Einsatzmöglichkeiten: IT-Fachkräfte werden in fast allen Branchen benötigt, von großen Unternehmen bis hin zu Start-ups. Es gibt zudem zahlreiche Remote-Positionen, was Flexibilität und vielfältige Entwicklungsmöglichkeiten bietet. Derzeit gibt es laut Indeed in Deutschland etwa 26.000 offene Stellen in diesem Bereich.</div>
                        <div class="section-bulletpoint">Karriere- und Einkommenspotenzial: Die hohe Nachfrage sorgt für gute Karrierechancen und ein attraktives Einkommenspotenzial.</div>
                        <div class="section-bulletpoint">Einstiegsmöglichkeiten für Quereinsteiger: Auch Personen aus anderen Berufsfeldern können durch passende Weiterbildung und Schulungen die erforderlichen Kompetenzen für eine Karriere im IT-Bereich erwerben.</div>
                    </div>
                    <div class="section-title">Insgesamt bietet eine Karriere im IT-Bereich, ob als Berater oder im Vertrieb, zukunftssichere, vielseitige und attraktive Möglichkeiten.</div>

                </div>
                    <div class="info-img-2"></div>
                </div>
            </div>
        </div>
        <style>${css}</style>
    `;
};

class CustomElem extends HTMLElement {
    constructor(){
        super();
    }

    connectedCallback(){
        var d = window.decodeURIComponent;
        this.options = JSON.parse(d(this.getAttribute('element-options')));
        this.innerHTML = renderInnerHTML(this.options);
        this.style.backgroundImage = `url('${this.options.imageURL}')`;
        this.removeAttribute('element-options');
    }
}

customElements.define(tagName, CustomElem);

function render(options){
    var e = window.encodeURIComponent;
    return `<${tagName} element-options="${e(JSON.stringify(options))}"></${tagName}>`;
}

export default render