const tagName = "product-info-section";

var productDescriptionHTML = {
    pm: /*html*/`
        Als ${config.courseData.pm.name} unterstützt du Unternehmen dabei, ihre <b>IT-Systeme und Geschäftsprozesse</b> durch die <b>Entwicklung und Implementierung von neuen Softwarelösungen</b> und die <b>Beratung bei der Auswahl von geeigneten Technologien</b> zu optimieren. Mit der Spezialisierung auf <b>Produktmanagement</b> bist du der <b>Experte</b> für die Entwicklung neuer <b>Softwareprodukte</b> und für die Verbesserung / Weiterentwicklung der bestehenden <b>Softwarelösungen.</b>
    `,
    sales: /*html*/`
        Als ${config.courseData.sales.name} öffnen sich <b>viele Vertriebsmöglichkeiten in verschiedenen Branchen</b> - auch außerhalb des IT-Bereichs. Neben dem Verkauf von Softwareprodukten ermöglicht die Weiterbildung, sich auf die <b>Gestaltung, Implementierung und Optimierung interner Vertriebsprozesse</b> sowie die Integration passender Tools zu konzentrieren. Mit entsprechender Erfahrung kann man als Teamleiter Verantwortung für die Leistungsüberwachung und Kennzahlenanalyse übernehmen. Die Qualifikation als ${config.courseData.sales.name} eröffnet Absolventen <b>vielfältige Karrierewege.</b>
    `
};

var css = /*css*/`
    ${tagName} {
        display: block;
        font-family: 'inter-medium';
    }
    ${tagName} .grid-outer-cont {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    ${tagName} .section-grid {
        max-width: 1000px;
        display: grid;
        gap: 40px;
        grid-template-columns: 1fr 1fr 1fr;
    }
    ${tagName} .section-grid .section-grid-box {
        padding: 20px 25px;
        border-radius: 20px;
        overflow: hidden;
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
    }
    ${tagName} .section-grid .section-grid-box:nth-child(1) {
        padding: 0;
        grid-column: 1;
        grid-row: 1;
    }
    ${tagName} .section-grid .section-grid-box:nth-child(2) {
        grid-column: 2 / 4;
        grid-row: 1;
        background: var(--light-gray);
        background: #EBEBEB;
    }
    ${tagName} .section-grid .section-grid-box:nth-child(3) {
        color: white;
        grid-column: 1 / 3;
        grid-row: 2;
        background: var(--gray-brand-gradient-light);
    }
    ${tagName} .section-grid .section-grid-box:nth-child(4) {
        grid-column: 3;
        grid-row: 2;
        padding: 0;
    }
    ${tagName} .info-img-1 {
        background-image: url('/assets/images-webp/product-manager-img.webp');
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: 50% 50%;
    }
    ${tagName} .info-img-2 {
        background-image: url('/assets/images-webp/philosophie-img-2.webp');
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: 50% 50%;
    }
    @media(max-width: 899px){
        ${tagName} .section-grid {
            grid-template-columns: 1fr 1fr;
        }
        ${tagName} .section-grid .section-grid-box:nth-child(1) {
            grid-column: 1;
            grid-row: 2;
            aspect-ratio: 1 / 1.5;
            display: none;
        }
        ${tagName} .section-grid .section-grid-box:nth-child(2) {
            grid-column: 1/3;
            grid-row: 1;
        }
        ${tagName} .section-grid .section-grid-box:nth-child(3) {
            grid-column: 1/3;
            grid-row: 3;
        }
        ${tagName} .section-grid .section-grid-box:nth-child(4) {
            grid-column: 1/3;
            grid-row: 2;
            aspect-ratio: 1.5 / 1;
        }
    }
    @media(max-width: 499px){
        ${tagName} .section-grid {
            grid-template-columns: 1fr;
        }
        ${tagName} .section-grid .section-grid-box:nth-child(1) {
            grid-column: 1;
            grid-row: 2;
            aspect-ratio: 1 / 1.3;
            display: initial;
        }
        ${tagName} .section-grid .section-grid-box:nth-child(2) {
            grid-column: 1;
            grid-row: 1;
        }
        ${tagName} .section-grid .section-grid-box:nth-child(3) {
            grid-column: 1;
            grid-row: 3;
        }
        ${tagName} .section-grid .section-grid-box:nth-child(4) {
            grid-column: 1;
            grid-row: 4;
            aspect-ratio: 1.3 / 1;
        }
    }
    ${tagName} .section-bulletpoints {
        display: flex;
        flex-direction: column;
        gap: 0.5em;
    }
    ${tagName} .section-bulletpoint b {
        font-family: 'inter-bold', sans-serif;
    }
    ${tagName} .section-bulletpoint:before {
        margin: 0 !important;
        margin-right: 0.5em !important;
        display: inline-flex;
        content: '';
        min-width: 12px;
        min-height: 12px;
        background-image: url('/assets/images/blue-arrow-double.svg');
        background-size: 100% 100%;
        transform: rotate(-90deg);
    }
    ${tagName} .section-text {
        margin-bottom: 2em;
        line-height: 1.6em;
    }
    ${tagName} .section-text b {
        font-family: 'inter-bold';
    }
    ${tagName} .section-title {
        margin-bottom: 1em;
        font-family: 'inter-bold';
        font-size: 1.2rem;
    }
`;

const renderInnerHTML = (options)=>{
    var slug = options.courseSlug;
    return /*html*/`
        <div class="grid-outer-cont">
            <div class="section-grid">
                <div class="section-grid-box">
                    <div class="info-img-1"></div>
                </div>
                <div class="section-grid-box">
                    <div class="section-title">Was macht ein ${config.courseData[slug].name}?</div>
                    <div class="section-text">${productDescriptionHTML[slug]}</div>
                    <div class="section-title">Mit unserer Weiterbildung erwarten dich:</div>
                    <div class="section-bulletpoints">
                        <div class="section-bulletpoint"><b>Attraktive Gehälter</b> und <b>Aufstiegsmöglichkeiten</b></div>
                        <div class="section-bulletpoint">Möglichkeiten für <b>Remote-Jobs</b></div>
                        <div class="section-bulletpoint"><b>Herausforderungen</b> und <b>Entwicklungspotential</b></div>
                        <div class="section-bulletpoint">Die Chance <b>positiven & wichtigen Einfluss</b> zu haben</div>
                        <div class="section-bulletpoint">Möglichkeit in <b>Führungsposition</b> zu arbeiten</div>
                    </div>
                </div>
                <div class="section-grid-box section-bulletpoints">
                    <div class="section-bulletpoint">Weiterbildung findet komplett <b>remote</b> statt.</div>
                    <div class="section-bulletpoint">Für den optimalen Start erhältst du ein <b>MacBook</b> von uns!</div>
                    <div class="section-bulletpoint"><b>Finanzierung durch die AfA</b> - durch den <b>Bildungsgutschein!</b></div>
                    <div class="section-bulletpoint">Inkl. <b>IHK Zertifikat</b> - perfekt für deinen Lebenslauf.</div>
                    <div class="section-bulletpoint"><b>Karrierecoaching</b> - unser Career-Success Team hilft dir <b>durchzustarten.</b></div>
                    <div class="section-bulletpoint">Wir helfen dir dabei, den <b>perfekten Job für dich</b> zu finden.</div>
                </div>
                <div class="section-grid-box">
                    <div class="info-img-2"></div>
                </div>
            </div>
        </div>
        <style>${css}</style>
    `;
};

class CustomElem extends HTMLElement {
    constructor(){
        super();
    }

    connectedCallback(){
        var d = window.decodeURIComponent;
        this.options = JSON.parse(d(this.getAttribute('element-options')));
        this.innerHTML = renderInnerHTML(this.options);
        this.style.backgroundImage = `url('${this.options.imageURL}')`;
        this.removeAttribute('element-options');
    }
}

customElements.define(tagName, CustomElem);

function render(options){
    var e = window.encodeURIComponent;
    return `<${tagName} element-options="${e(JSON.stringify(options))}"></${tagName}>`;
}

export default render