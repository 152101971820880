const tagName = "parter-section";

var css = /*css*/`
    ${tagName} {
        display: block;
        padding: 3rem 1rem;
    
    }
    ${tagName} .partner-title {
        font-family: 'inter-bold';
        font-size: 28px !important;
        font-size: 2rem;
        text-align: center;
        margin-bottom: 1em;
        margin-top: -20px;
    }
    ${tagName} .partner-wrapper {
        display: grid;
        gap: 20px;
        justify-content: space-evenly;
        grid-template-columns: repeat(6, 1fr);
        margin-left: auto;
        margin-right: auto;
    }
    ${tagName} .partner-wrapper .partner-logo {
        width: 140px;
        height: 40px;
        background-size: contain;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        margin: auto auto;
        transition: 200ms;
    }
    ${tagName} .partner-wrapper .partner-logo:hover {
        transform: translateY(-3px);
    }
    ${tagName} .partner-wrapper .partner-logo.partner-pp {
        background-image: url('/assets/images/product-people-logo.png');
    }
    ${tagName} .partner-wrapper .partner-logo.partner-ixdf {
        background-image: url('/assets/images/ixdf-logo-black-medium.png');
    }
    ${tagName} .partner-wrapper .partner-logo.partner-miro {
        background-image: url('/assets/images/miro-logo.svg');
        background-size: 100%;
    }
    ${tagName} .partner-wrapper .partner-logo.partner-webflow {
        background-image: url('/assets/images/webflow-logo.svg');
    }
    ${tagName} .partner-wrapper .partner-logo.partner-uizard {
        background-image: url('/assets/images/uizard_logo_yellow.svg');
    }
    ${tagName} .partner-wrapper .partner-logo.partner-ihk-muc {
        background-image: url('/assets/images/ihk-akademie-muenchen-oberbayern.svg');
    }
    @media(max-width: 1100px){
        ${tagName} .partner-wrapper {
            grid-template-columns: repeat(3, 1fr);
            gap: 40px;
            max-width: 800px;
        }
    }
    @media(max-width: 572px){
        ${tagName} .partner-wrapper {
            grid-template-columns: repeat(2, 1fr);
            gap: 40px;
        }
    }
    @media(max-width: 399px){
        ${tagName} .partner-wrapper {
            grid-template-columns: repeat(1, 1fr);
            gap: 40px;
        }
    }
`;

var template = /*html*/`
    <div class="partner-title">Unsere Partner</div>
    <div class="partner-wrapper">
        <a href="https://www.getproductpeople.com" target="_blank" class="partner-logo partner-pp"></a>
        <a href="https://www.interaction-design.org/" target="_blank" class="partner-logo partner-ixdf"></a>
        <a href="https://miro.com/" target="_blank" class="partner-logo partner-miro"></a>
        <a href="https://webflow.com/" target="_blank" class="partner-logo partner-webflow"></a>
        <a href="https://uizard.io/" target="_blank" class="partner-logo partner-uizard"></a>
        <a href="https://www.ihk-akademie-muenchen.de/" target="_blank" class="partner-logo partner-ihk-muc"></a>
    </div>
    <style>
        ${css}
    </style>
`;

class CustomElem extends HTMLElement {
    constructor(options){
        super();
    }

    connectedCallback(){
        this.innerHTML = template;
    }
}

customElements.define(tagName, CustomElem);

export default `<${tagName}></${tagName}>`