// Define the component tag name
const tagName = "warum-fias-grid-component";

// Define the CSS for the component
var css = /*css*/`
:host {
    display: block;
    padding: 20px;
    background-color: #ffffff;
    width: 100%;
    box-sizing: border-box;
}

.hero-3 {
    font-size: 24px;
    color: var(--blue-brand);
    text-align: center;
    font-weight: bold;
    margin-bottom: 20px;
    padding-top: 40px; /* Added padding above the text */
}

/* Flexbox layout for four boxes in one row */
.four-field-grid-cont {
    display: flex;
    justify-content: space-between;
    gap: 20px; /* Reduced gap between boxes */
    flex-wrap: wrap; /* Allow wrapping for smaller screens */
    padding: 0 150px; /* Increased space between first/last boxes and webpage edges */
}

.box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    background: linear-gradient(135deg, #f0f0f0, #d9d9d9); /* Light gradient background */
    border-radius: 12px; /* Rounded corners */
    padding: 20px;
    flex: 1;
    box-sizing: border-box;
    width: 170px; /* Decreased maximum width */
    max-height: 250px; /* Increased height */
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Box shadow for depth */
}

/* Flexbox to align icon and title side by side */
.icon-title-container {
    display: flex;
    align-items: center; /* Align icon and title vertically */
}

.partner-benefit-icon {
    width: 60px;
    height: 60px;
    background-color: var(--gray-brand); /* Set background to --gray-brand */
    border-radius: 100%; /* Circular icon background */
    background-size: 55%; /* Reduce the icon size inside the circle */
    background-position: center;
    margin-right: 15px; /* Space between icon and title */
    background-repeat: no-repeat;
}

/* Different icons for each box */
.vermittler-icon-1 {
    background-image: url('/assets/images/icons/vermittler-icon-test.svg');
}

.vermittler-icon-2 {
    background-image: url('/assets/images/icons/vermittler-icon-group.svg');
}

.vermittler-icon-3 {
    background-image: url('/assets/images/icons/vermittler-icon-dozenten.svg');
}

.vermittler-icon-4 {
    background-image: url('/assets/images/icons/vermittler-icon-programm.svg');
}

.partner-benefit-title {
    font-size: 18px;
    font-weight: bold;
    color: #333;
    line-height: 1.2;
}

.partner-benefit-text {
    font-size: 14px;
    color: #666;
    line-height: 1.5;
    margin-top: 10px; /* Space between the title and the text */
}

/* Responsive design: Adjust layout for smaller screens */
@media (max-width: 1610px) {
    .partner-benefit-title {
        font-size: 16px; /* Decrease title font size */
    }
}

@media (max-width: 1389px) {
    .four-field-grid-cont {
        flex-wrap: wrap;
        padding: 0 120px; /* Adjusted padding for medium screens */
    }

    .box {
        max-width: 30%; /* Three boxes in one row, each taking 30% of the width */
        flex: 0 0 30%; /* Ensures boxes stay at 30% width */
    }

    .box:nth-child(4) {
        max-width: 100%; /* The fourth box takes the entire width */
        flex: 0 0 100%;
    }
}

@media (max-width: 1024px) {
    .four-field-grid-cont {
        flex-wrap: wrap;
        padding: 0 60px; /* Adjusted padding for medium screens */
    }

    .box {
        max-width: 48%; /* Two boxes per row */
    }

    .partner-benefit-title {
        font-size: 15px; /* Adjust title size for medium screens */
    }
}

@media (max-width: 984px) {
    .four-field-grid-cont {
        padding: 0 40px; /* Adjust padding for smaller screens */
        justify-content: space-between; /* Distribute space between boxes */
    }

    .box {
        max-width: 48%; /* Two boxes per row */
        flex: 0 0 48%; /* Ensure each box takes 48% of the width */
        margin-bottom: 20px; /* Space between rows */
    }

    /* Ensure the fourth box doesn't take full width anymore */
    .box:nth-child(4) {
        max-width: 48%; /* Fourth box takes the same width as others */
        flex: 0 0 48%; /* Reset the width to fit two per row */
    }
}

/* New media query for screen sizes smaller than 617px */
@media (max-width: 617px) {
    .four-field-grid-cont {
        padding: 0 20px; /* Adjust padding for smaller screens */
    }

    .box {
        max-width: 100%; /* One box per row */
        flex: 0 0 100%; /* Ensure the box takes the full width */
        margin-bottom: 20px; /* Space between rows */
    }

    .box:nth-child(4) {
        max-width: 100%; /* One box per row */
        flex: 0 0 100%;/* Reset the width to fit two per row */
    }
}

`;

// Define the HTML structure for the component
var template = /*html*/`
<div class="hero-3 blue-font centered">Warum das Franklin IAS?</div>
<div class="four-field-grid-cont">
    <!-- Box 1 -->
    <div class="box">
        <div class="icon-title-container">
            <div class="partner-benefit-icon vermittler-icon-1"></div>
            <div class="partner-benefit-title">Sorfältige <br> Eignungs-Prüfung</div>
        </div>
        <div class="partner-benefit-text">
            Alle Interessenten werden durch einen fundierten, schriftlichen Eignungstest, sowie persönliche Gespräche auf Eignung für unsere Weiterbildungen geprüft.
        </div>
    </div>

    <!-- Box 2 -->
    <div class="box">
        <div class="icon-title-container">
            <div class="partner-benefit-icon vermittler-icon-2"></div>
            <div class="partner-benefit-title">Kleine Lerngruppen</div>
        </div>
        <div class="partner-benefit-text">
            Wir setzen auf kleine, homogene Lerngruppen. So stellen wir sicher, dass sie den maximalen Lernerfolg erlangen und individuell von unseren Dozenten betreut werden können.
        </div>
    </div>

    <!-- Box 3 -->
    <div class="box">
        <div class="icon-title-container">
            <div class="partner-benefit-icon vermittler-icon-3"></div>
            <div class="partner-benefit-title">Qualifizierte Dozenten</div>
        </div>
        <div class="partner-benefit-text">
            Unsere Dozenten kommen direkt aus der Praxis. Unternehmensberater, IT-Spezialisten und Doktoranten - bei uns lehren Experten aus den wichtigsten Bereichen.
        </div>
    </div>

    <!-- Box 4 -->
    <div class="box">
        <div class="icon-title-container">
            <div class="partner-benefit-icon vermittler-icon-4"></div>
            <div class="partner-benefit-title">Karriere-Programm</div>
        </div>
        <div class="partner-benefit-text">
            Wir vermitteln unseren Teilnehmern nicht nur das nötige Fachwissen, sondern vermitteln diese auch in einen passenden, zukunftssicheren Job - mit unserem Karriereprogramm.
        </div>
    </div>
</div>
`;

// Define the WarumFiasComponent class
class WarumFiasComponent extends HTMLElement {
    constructor() {
        super();
    }

    connectedCallback() {
        // Attach Shadow DOM to isolate component styles and markup
        const shadow = this.attachShadow({ mode: "open" });

        // Create a container to hold the template and styles
        const container = document.createElement("div");
        container.innerHTML = template;

        const style = document.createElement("style");
        style.textContent = css;

        shadow.appendChild(style);
        shadow.appendChild(container);
    }
}

// Register the custom element
customElements.define(tagName, WarumFiasComponent);

export default WarumFiasComponent;
