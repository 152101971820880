const tagName = "yt-video-section";

var videoURL = "https://www.youtube-nocookie.com/embed/5q3nu1sOFwE?autoplay=1&rel=0&modestbranding=1&showinfo=0";

var css = /*css*/`
    ${tagName} {
        display: block;
        margin-top: 1rem;
    }
    ${tagName} .video-thumbnail {
        width: 100%;
        max-width: 900px;
        margin: 0 auto;
        margin-top: 2rem;
        margin-bottom: 6rem;
        aspect-ratio: 16 / 9;
        background-image: url('/assets/images-webp/hero-video-thumbnail-bg.webp');
        background-position: 50% 50%;
        background-size: cover;
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        position: relative;
        cursor: pointer;
        box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.1);
    }
    ${tagName} .video-btn {
        width: 90px;
        aspect-ratio: 1 / 1;
        border-radius: 50%;
        background: #252629;
        transition: 0.2s;
        background-image: url("data:image/svg+xml,%3Csvg width='619' height='619' viewBox='0 0 619 619' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='mask0_1627_949' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='619' height='619'%3E%3Ccircle cx='309.5' cy='309.5' r='309.5' fill='%23252629'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_1627_949)'%3E%3Cpath d='M461.5 298.242C470.167 303.245 470.167 315.755 461.5 320.758L243.25 446.765C234.583 451.769 223.75 445.514 223.75 435.507V183.493C223.75 173.486 234.583 167.231 243.25 172.235L461.5 298.242Z' fill='%2300D5EA'/%3E%3C/g%3E%3C/svg%3E%0A");
        background-position: 50% 50%;
        background-size: 90px;
        background-repeat: no-repeat;
        animation: 1500ms btn-pulse infinite;
    }
    ${tagName} .video-thumbnail:hover .video-btn {
        background: white;
        background-image: url("data:image/svg+xml,%3Csvg width='619' height='619' viewBox='0 0 619 619' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='mask0_1627_949' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='619' height='619'%3E%3Ccircle cx='309.5' cy='309.5' r='309.5' fill='%23252629'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_1627_949)'%3E%3Cpath d='M461.5 298.242C470.167 303.245 470.167 315.755 461.5 320.758L243.25 446.765C234.583 451.769 223.75 445.514 223.75 435.507V183.493C223.75 173.486 234.583 167.231 243.25 172.235L461.5 298.242Z' fill='%2300D5EA'/%3E%3C/g%3E%3C/svg%3E%0A");
        background-position: 50% 50%;
        background-size: 120px;
        background-repeat: no-repeat;
    }
    @keyframes btn-pulse {
        0% {
            transform: scale(1);
        }
        50% {
            transform: scale(1.1);
        }
        100% {
            transform: scale(1);
        }
    }
    ${tagName} .video-thumbnail>div>iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 20px;
        overflow: hidden;
    }
`;

const renderInnerHTML = (options)=>{
    return /*html*/`
        <div class="section-message page-hero-medium centered font-scale-down" style="margin-bottom: 0.3em;">Was ist Produktmanagement überhaupt?</div>
        <div class="section-message centered" style="font-size: 1.3em;">Klicke auf das Video um mehr über deine Karrierechancen im Produktmanagement zu erfahren.</div>
        <div class="video-thumbnail" id="video">
            <div class="video-btn"></div>
        </div>
        <style>${css}</style>
    `;
};

class CustomElem extends HTMLElement {
    constructor(){
        super();
    }

    connectedCallback(){
        var d = window.decodeURIComponent;
        this.options = JSON.parse(d(this.getAttribute('element-options')));
        this.innerHTML = renderInnerHTML(this.options);
        this.style.backgroundImage = `url('${this.options.imageURL}')`;
        this.removeAttribute('element-options');
        this.initVideo();
    }

    initVideo(){
        var btn = this.querySelector('.video-thumbnail');
        if(location.href.includes('#video')){
            window.scrollTo(0, btn.offsetTop - 200);
        }
        function insertYT(){
            var iframeHTML = `<iframe width="560" height="315" src="${videoURL}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`;
            var iframeCont = document.createElement('div');
            iframeCont.innerHTML = iframeHTML;
            btn.appendChild(iframeCont);
            btn.onclick = '';
        }
        btn.onclick = insertYT;
    }
}

customElements.define(tagName, CustomElem);

function render(options){
    var e = window.encodeURIComponent;
    return `<${tagName} element-options="${e(JSON.stringify(options))}"></${tagName}>`;
}

export default render