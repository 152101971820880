const tagName = "counter-widget-component";

var counterCss = /*css*/`
.counter-section {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 60px;  /* Spacing between the boxes */
    padding: 40px 350px;
    background-color: #ffffff;
    grid-auto-rows: 1fr;  /* Make sure all rows have the same height */
}

.counter-box {
    background: white;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    padding: 20px;
    text-align: center;
    transition: transform 0.2s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90%;  /* Ensures the box takes the full width of its grid cell */
    height: 230px;  /* Ensures a fixed height for all boxes */
    cursor: pointer;
}

.counter-box.middle-box {
    background-color: var(--blue-brand);
}

.counter-box.middle-box .counter {
    color: white;  /* White counter for the middle box */
}

.counter-box.middle-box p {
    color: white;  /* White text for the middle box */
}

.counter-box:hover {
    transform: translateY(-5px);
}

/* Individual icon classes */
.icon-teilnehmer {
    width: 80px;  /* Size for first icon */
    height: auto;
    margin-bottom: 10px;
}

.icon-vermittlung {
    width: 80px;  /* Size for second icon */
    height: auto;
    margin-bottom: 10px;
}

.icon-bewertungen {
    width: 160px;  /* Size for third icon */
    height: auto;
    margin-bottom: 20px;
    margin-top: 10px;
}

.counter {
    font-size: 36px;
    color: var(--blue-brand);  /* Default color for the counter */
    margin: 10px 0;
    font-family: inter-bold;
}

p {
    font-size: 20px;
    color: #333;
    font-family: inter-medium;
}

/* Responsive adjustments */

@media (max-width: 953px) {
    .counter-section {
        grid-template-columns: 1fr;  /* Stack boxes vertically on smaller screens */
        padding: 20px 10px;  /* Reduce padding between the boxes and screen walls */
    }

    .counter-box {
        max-width: 100%;
    }
}

@media (max-width: 1192px) {
    .counter-section {
        grid-template-columns: 1fr;  /* Stack boxes vertically on smaller screens */
        padding: 40px;
    }

    .counter-box {
        max-width: 100%;
    }
}


@media (max-width: 1605px) {
    .counter-section {
        grid-template-columns: repeat(2, 1fr);  /* Two equal columns */
        grid-template-rows: auto;
        gap: 30px;
        padding: 40px 200px;
    }

    .counter-box:nth-child(2) {
        grid-column: 1 / 3;  /* Span across both columns */
        grid-row: 2;  /* Place the middle box on the second row */
        justify-self: center;
    }
}

@media (max-width: 835px) {
    .counter-section {
        grid-template-columns: 1fr;  /* Stack all boxes vertically */
        grid-template-rows: auto;    /* Automatically adjust rows */
        width: 80%;
        length: auto;
        justify-self: center;
        padding: 75px;
    }

    /* Ensure the second box (middle blue box) stays in the middle */
    .counter-box:nth-child(2) {
        grid-column: auto;  /* Reset column span */
        grid-row: auto;     /* Reset row placement */
    }

    .counter-box {
        width: 80%;  /* Ensure the boxes take full width */
        justify-self: center;
        

    }
}

@media (max-width: 835px) {
    .counter-section {
        padding: 60px;
    }
}

@media (max-width: 460px) {
    .counter-section {
        padding: 40px;
    }
}


`;

var counterTemplate = /*html*/`
    <section class="counter-section">
        <div class="counter-box">
            <img src="/assets/images/icons/Teilnehmer-Icon.png" class="icon icon-teilnehmer" alt="Teilnehmer Icon">
            <div class="counter" data-target="200" data-suffix="+">0</div>
            <p>zufriedener Teilnehmern</p>
        </div>
        <div class="counter-box middle-box">
            <img src="/assets/images/icons/Vermittlung-Icon.png" class="icon icon-vermittlung" alt="Vermittlungsquote Icon">
            <div class="counter" data-target="93.7" data-suffix="%">0%</div>
            <p>Vermittlungsquote</p>
        </div>
        <div class="counter-box">
            <img src="/assets/images/icons/5-Sterne-Icon.png" class="icon icon-bewertungen" alt="Bewertungen Icon">
            <div class="counter" data-target="172" data-suffix="+">0</div>
            <p>5 Sterne Bewertungen</p>
        </div>
    </section>
`;

class CounterWidget extends HTMLElement {
    constructor() {
        super();
    }

    connectedCallback() {
        // Attach Shadow DOM to isolate component styles and markup
        const shadow = this.attachShadow({ mode: "open" });

        // Create a container to hold the template and styles
        const container = document.createElement("div");
        container.innerHTML = counterTemplate;

        const style = document.createElement("style");
        style.textContent = counterCss;

        shadow.appendChild(style);
        shadow.appendChild(container);

        // Initialize counter animation
        this.initCounters(shadow);
    }

    initCounters(shadow) {
        const counters = shadow.querySelectorAll('.counter');
        const speed = 50; // Animation speed

        const runCounter = () => {
            counters.forEach(counter => {
                const target = +counter.getAttribute('data-target');
                const suffix = counter.getAttribute('data-suffix') || ""; // Get suffix

                const updateCount = () => {
                    let currentValue = +counter.innerText.replace(suffix, ""); // Remove suffix to get numeric value
                    const increment = target / speed;

                    if (currentValue < target) {
                        currentValue = Math.ceil(currentValue + increment);
                        counter.innerText = `${currentValue}${suffix}`;
                        setTimeout(updateCount, 20);
                    } else {
                        counter.innerText = `${target}${suffix}`; // Final value with suffix
                    }
                };
                updateCount();
            });
        };

        // Observe when the component is in view to start animation
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    runCounter();
                    observer.disconnect(); // Stop observing after animation
                }
            });
        }, { threshold: 0.5 });

        observer.observe(shadow.querySelector('.counter-section'));
    }
}

customElements.define(tagName, CounterWidget);

export default CounterWidget;