const tagName = "faq-box";

var css = /*css*/`
    ${tagName} {
        display: flex;
        flex-direction: column;
        gap: 1em;
        color: white;
    }

    ${tagName} .faq-dropdown {
        cursor: pointer;
        background: var(--gray-brand-gradient);
        border-radius: 0.5em;
        box-shadow: 0px 10px 20px rgb(0 0 0 / 50%);
    }

    ${tagName} .faq-question {
        font-family: 'inter-medium';
        font-size: 1.3rem;
    }

    ${tagName} .faq-dropdown-inner {
        display: flex;
        gap: 20px;
        justify-content: space-between;
        align-items: center;
        padding: 1.5em 1.2em;
    }

    ${tagName} .faq-dropdown-inner .faq-question {
        transition: color 0.5s;
    }

    body.has-hover ${tagName} .faq-dropdown-inner:hover {
        color: var(--blue-brand);
    }

    ${tagName} .faq-arrow {
        display: flex;
        color: white;
        min-width: 30px;
        min-height: 30px;
        padding: 0;
        margin: 0;
        background-image: url('/assets/images/blue-arrow-single.svg');
        background-size: 100% 100%;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        transition: 0.2s;
        -webkit-transition: 0.2s;
    }

    ${tagName} .faq-arrow-active {
        transform: rotate(180deg);
    }

    ${tagName} .faq-answer-wrapper {
        height: 0;
        overflow: hidden;
    }

    ${tagName} .faq-answer {
        cursor: initial;
        line-height: 1.5em;
        padding: 1em 1.2em;
        padding-top: 0;
        font-family: 'inter-medium';
        font-size: 1.1em;
    }
`;

function renderSingleFAQ(qa){
    return /*html*/`
        <div class="faq-dropdown">
            <div class="faq-dropdown-inner">
                <div class="faq-question">${qa.q}</div>
                <div class="faq-arrow"></div>
            </div>
            <div class="faq-answer-wrapper">
                <div class="faq-answer">${qa.a}</div>
            </div>
        </div>
    `;
}

const renderInnerHTML = (options)=>{
    var faqHTML = "";
    options.forEach(qa=>{
        faqHTML += renderSingleFAQ(qa);
    });
    return /*html*/`
        ${faqHTML}
        <style>${css}</style>
    `;
};

var faqSpeed = 200;

class CustomElem extends HTMLElement {
    constructor(){
        super();
    }

    connectedCallback(){
        var d = window.decodeURIComponent;
        this.options = JSON.parse(d(this.getAttribute('element-options')));
        this.innerHTML = renderInnerHTML(this.options);
        this.removeAttribute('element-options');
        this.hydrate();
    }

    hydrate(){
        var parent = this;
        this.QAs = Array.from(this.querySelectorAll('.faq-dropdown'));
        this.QAs.forEach(QA=>{
            QA.expandBtn = QA.querySelector('.faq-arrow');
            QA.top = QA.querySelector('.faq-dropdown-inner');
			QA.expanded = false;
			QA.answer = QA.querySelector('.faq-answer-wrapper');
			QA.answer.style.height = '0px';
			QA.answer.style.opacity = '0';
			QA.expand = function(){
				parent.QAs.forEach(function(QA){
					QA.contract();
				});
				QA.expanded = true;
				QA.expandBtn.classList.add('faq-arrow-active');
				QA.answer.animate({height: QA.answer.scrollHeight + 'px', opacity: '1'}, {duration: faqSpeed, fill: 'forwards'});
			}
			QA.contract = function(){
				QA.expanded = false;
				QA.expandBtn.classList.remove('faq-arrow-active');
				QA.answer.animate({height: '0px', opacity: '0'}, {duration: faqSpeed, fill: 'forwards'});
			}
			QA.handleClick = function(){
				if(!QA.expanded){
					QA.expand();
				}else{
					QA.contract();
				}
			}
			QA.top.onclick = QA.handleClick;
        });
    }
}

customElements.define(tagName, CustomElem);

function render(options){
    var e = window.encodeURIComponent;
    return `<${tagName} element-options="${e(JSON.stringify(options))}"></${tagName}>`;
}

export default render