import DoubleCTABox from '/components/double-cta-box.js'
import '/config.js'
import '/services/util.js'

const tagName = "partner-page";

var pageTitle = "Für Unternehmen | Franklin Institute of Applied Sciences";
var pageDescription = "Sichern Sie sich qualifizierte IT-Fachkräfte für Ihr Unternehmen mit dem Partnerprogramm des Franklin Institute of Applied Sciences. Wir bieten maßgeschneiderte Lösungen für die planbare Gewinnung und das Upskilling von IT-Talenten. Finden Sie in unserem Talentpool die passenden Kandidaten und sparen Sie Zeit und Geld. Profitieren Sie von schnelleren Onboarding-Prozessen und einem exklusiven Zugang zu unseren Absolventen. Vereinbaren Sie jetzt einen Termin mit unserem Partner Management und starten Sie durch. Jetzt Partner werden!";

var css = /*css*/`
    // ${tagName} {
    //     display: block;
    //     margin: 0;
    //     padding: 0;
    //     border: none;
    //     outline: none;
    //     background: var(--gray-brand);
    // }
    // ${tagName} iframe {
    //     background: var(--gray-brand);
    // }
`;

var template = /*html*/`
    <iframe id="test-frame" src="/legacy-website/frames/partner_frame.html" frameborder="0" width="100%" scrolling="no" height="2000px" title="${pageTitle}"></iframe>
    <style>${css}</style>
`;

class Page extends HTMLElement {
    constructor(){
        super();
    }

    connectedCallback(){
        util.setPageTitle(pageTitle);
        util.setPageDescription(pageDescription);
        this.innerHTML = template;
        this.iframeElem = this.querySelector('iframe');
        this.messageEvent = eventBus.register('message', (options)=>{
            this.handleIframeMessage(options);
        });
    }

    disconnectedCallback(){
        this.messageEvent.unregister();
    }

    handleIframeMessage(message){
        try{
            var msg = JSON.parse(message);
            if(msg.type == "height"){
                var setHeight = Math.floor(msg.data) + 5 + "px";
                this.iframeElem.height = setHeight;
            }
            if(msg.type == "cta"){
                this.querySelector('a[cta-emulator]').click();
            }
        }catch{}
    }
}

customElements.define(tagName, Page);

export default `<${tagName}></${tagName}>`