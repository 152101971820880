import '/services/util.js'
import HeroButton from '/components/hero-button.js'

const tagName = "notfound-page";

var pageTitle = "404 | Franklin Institute of Applied Sciences";
var pageDescription = "Das Franklin Institute bietet zu 100% staatliche geförderte IT-Weiterbildungen und Karriereunterstützung für Arbeitssuchende. Entdecke deine Möglichkeiten in der IT-Branche mit unserem umfassenden Kursangebot, erfahrenen Dozenten und individueller Beratung. Starte jetzt deine erfolgreiche IT-Karriere mit uns.";

var css = /*css*/`
    ${tagName} {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 3rem;
    }
    ${tagName} .section-title {
        font-size: 6rem;
        font-family: 'inter-bold';
        text-align: center;
    }
    ${tagName} .section-text {
        font-size: 1rem;
        font-family: 'inter-bold';
        text-align: center;
    }
    ${tagName} .section-btns {
        display: flex;
        gap: 1em;
        margin-top: 2rem;
    }
`;

var template = /*html*/`
    <div class="section-title">404</div>
    <div class="section-text">Diese Seite konnte nicht gefunden werden.</div>
    <div class="section-btns">
        <a cta-prevent-inner-hover onclick="history.back()" style="cursor: pointer;">${HeroButton(/*html*/`<span>Zurück</span>`, {noBorder: true, shadow: true})}</a>
        <a cta-prevent-inner-hover internal href="/">${HeroButton(/*html*/`<span>Zur Startseite</span>`, {noBorder: true, shadow: true})}</a>
    </div>
    <style>${css}</style>
`;

class Page extends HTMLElement {
    constructor(){
        super();
    }

    connectedCallback(){
        util.setPageTitle(pageTitle);
        util.setPageDescription(pageDescription);
        this.innerHTML = template;
    }
}

customElements.define(tagName, Page);

export default `<${tagName}></${tagName}>`