const tagName = "employer-marquee";

var css = /*css*/`
    ${tagName} {
        display: block;
    }
    ${tagName} .blue-line {
        height: 0.5rem;
        background: var(--blue-wide-gradient);
    }
    ${tagName} .hero-2 {
        font-family: 'inter-bold', sans-serif;
        font-size: 1.6em;
        margin-bottom: 0 !important;
    }
    ${tagName} .partner-companies-cont {
        overflow-x: hidden;
        max-width: 1200px;
        margin: 0 auto;
    }
    ${tagName} .employer-fade-in-out {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        z-index: 1;
    }
    ${tagName} .employer-fade-inner-cont {
        position: relative;
        max-width: 1200px;
        height: 100%;
        margin: 0 auto;
    }
    ${tagName} .employer-fade-in {
        width: 200px;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-image: linear-gradient(to right, #F2F6F7 0%, transparent 100%);
        z-index: 5;
    }
    ${tagName} .employer-fade-out {
        width: 200px;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        background-image: linear-gradient(to left, #F2F6F7 0%, transparent 100%);
        z-index: 5;
    }
    ${tagName} .light-blue-bg {
        background: var(--light-blue);
    }
    ${tagName} .partner-companies-icon-wrapper {
        width: fit-content;
        position: relative;
        display: flex;
        margin-top: 35px;
        animation: employer-slide 40s linear infinite;
    }
    @keyframes employer-slide {
        0% {
            transform: translateX(0%);
        }
        100% {
            transform: translateX(-25%);
        }
    }
    ${tagName} .employer-logo {
        height: 40px;
        display: flex;
        background-size: contain;
        background-repeat: no-repeat;
        margin-left: 50px;
    }
    ${tagName} .employer-logo-1 {
        background-image: url('/assets/employer-logos/employer-logo-bosch.svg');
        aspect-ratio: 732 / 163;
    }
    ${tagName} .employer-logo-2 {
        background-image: url('/assets/employer-logos/employer-logo-ey.svg');
        aspect-ratio: 296 / 299;
        height: 50px;
        position: relative;
        bottom: 10px;
    }
    ${tagName} .employer-logo-3 {
        background-image: url('/assets/employer-logos/employer-logo-ibm.svg');
        aspect-ratio: 607 / 244;
    }
    ${tagName} .employer-logo-4 {
        background-image: url('/assets/employer-logos/employer-logo-lufthansa.svg');
        aspect-ratio: 709 / 122;
    }
    ${tagName} .employer-logo-5 {
        background-image: url('/assets/employer-logos/employer-logo-microsoft.svg');
        aspect-ratio: 924 / 198;
    }
    ${tagName} .employer-logo-6 {
        background-image: url('/assets/employer-logos/employer-logo-n26.svg');
        aspect-ratio: 454 / 373;
        height: 50px;
    }
    ${tagName} .employer-logo-7 {
        background-image: url('/assets/employer-logos/employer-logo-sap.svg');
        aspect-ratio: 455 / 225;
        height: 50px;
    }
    ${tagName} .employer-logo-8 {
        background-image: url('/assets/employer-logos/employer-logo-zalando.svg');
        aspect-ratio: 1013 / 186;
    }
    @media(max-width: 899px){
        ${tagName} .employer-fade-in-out {
            display: none;
        }
        ${tagName} .partner-companies-icon-wrapper {
            animation-duration: 20s;
            margin-top: 25px;
        }
        ${tagName} .employer-logo {
            height: 30px !important;
            display: flex;
            background-size: contain;
            background-repeat: no-repeat;
            margin-left: 50px;
            bottom: 0px !important;
        }
        ${tagName} .employer-padding {
            padding-bottom: 30px !important;
        }
    }
`;

var template = /*html*/`
    <div class="blue-line"></div>
    <div class="light-blue-bg employer-padding" style="padding: 20px 0; padding-bottom: 30px; position: relative;">
        <div class="hero-2" style="text-align: center;">Potentielle Arbeitgeber:</div>
        <div class="partner-companies-cont">
            <div class="partner-companies-icon-wrapper">
                <div class="employer-logo employer-logo-1"></div>
                <div class="employer-logo employer-logo-2"></div>
                <div class="employer-logo employer-logo-3"></div>
                <div class="employer-logo employer-logo-4"></div>
                <div class="employer-logo employer-logo-6"></div>
                <div class="employer-logo employer-logo-7"></div>
                <div class="employer-logo employer-logo-8"></div>
                <div class="employer-logo employer-logo-1"></div>
                <div class="employer-logo employer-logo-2"></div>
                <div class="employer-logo employer-logo-3"></div>
                <div class="employer-logo employer-logo-4"></div>
                <div class="employer-logo employer-logo-6"></div>
                <div class="employer-logo employer-logo-7"></div>
                <div class="employer-logo employer-logo-8"></div>
                <div class="employer-logo employer-logo-1"></div>
                <div class="employer-logo employer-logo-2"></div>
                <div class="employer-logo employer-logo-3"></div>
                <div class="employer-logo employer-logo-4"></div>
                <div class="employer-logo employer-logo-6"></div>
                <div class="employer-logo employer-logo-7"></div>
                <div class="employer-logo employer-logo-8"></div>
                <div class="employer-logo employer-logo-1"></div>
                <div class="employer-logo employer-logo-2"></div>
                <div class="employer-logo employer-logo-3"></div>
                <div class="employer-logo employer-logo-4"></div>
                <div class="employer-logo employer-logo-6"></div>
                <div class="employer-logo employer-logo-7"></div>
                <div class="employer-logo employer-logo-8"></div>
            </div>
        </div>
        <div class="employer-fade-in-out">
            <div class="employer-fade-inner-cont">
                <div class="employer-fade-in"></div>
                <div class="employer-fade-out"></div>
            </div>
        </div>
    </div>
    <div class="blue-line"></div>
    <style>
        ${css}
    </style>
`;

class CustomElem extends HTMLElement {
    constructor(options){
        super();
    }

    connectedCallback(){
        this.innerHTML = template;
    }
}

customElements.define(tagName, CustomElem);

export default `<${tagName}></${tagName}>`