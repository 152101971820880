import VermittlerHeroComponent from '/components/vermittler-hero-component.js';
import WarumFiasComponent from '../components/warum-fias-grid-component.js';
import CounterWidget from '../components/counter-widget-component.js';
import CourseCard from '/components/course-card.js';
import FCC6SchritteComponent from '/components/fcc-6-schritte-component.js';
import WarumPmSection from '/sections/warum-pm-section.js';


const tagName = "vermittler-new-page";

var pageTitle = "Für Arbeitsvermittler | Franklin Institute of Applied Sciences";
var pageDescription = "Wir geben durch Weiterbildungen und Arbeitsvermittlung Talenten mit unterschiedlichsten beruflichen Hintergründen die Möglichkeit, ihr volles Potenzial in der IT-Branche auszuschöpfen.";

var css = /*css*/`
${tagName} .course-card-cont {
  display: flex;
  gap: 2em;
  margin: 0 auto;
  width: 100%;
  justify-content: center;
  flex-wrap: wrap;
}

${tagName} .course-card-cont course-card {
  width: 450px;
}

@media (max-width: 490px) {
  ${tagName} .course-card-cont {
      gap: 1em;
  }
}

${tagName} .course-card-title {
  font-family: 'inter-bold';
  font-size: clamp(1.5rem, 9.375vw, 3rem);
  text-align: center;
  margin-bottom: 0.8em;
}
`;

var template = /*html*/`
  <vermittler-hero-component></vermittler-hero-component>
  <warum-fias-grid-component></warum-fias-grid-component>
  <counter-widget-component></counter-widget-component>
    <div class="wrapper">
        <div class="course-card-title" id="courses">Unsere Weiterbildungen</div>
        <div class="course-card-cont">
            ${CourseCard({
                title: "IT-Berater<br>im Produkt-<br>management",
                bulletPoints: [
                    "Inkl. Karrierecoaching",
                    "100% finanziert durch die Agentur für Arbeit",
                    "Inkl. MacBook",
                    `${config.courseData.pm.minCertCount} anerkannte Zertifikate + IHK Zertifikat`
                ],
                imageURL: "/assets/images-webp/course-banner-img-pm.webp",
                ctaURL: "/courses/pm"
            })}
            ${CourseCard({
                title: "IT-Sales<br>Manager &<br>Solution<br>Architect",
                bulletPoints: [
                    "Inkl. Karrierecoaching",
                    "100% finanziert durch die Agentur für Arbeit",
                    "Inkl. MacBook",
                    `${config.courseData.sales.minCertCount} anerkannte Zertifikate + IHK Zertifikat`
                ],
                imageURL: "/assets/images-webp/course-banner-img-sales.webp",
                ctaURL: "/courses/sales"
            })}
        </div>
    </div>

    <fcc-6-schritte-component></fcc-6-schritte-component>

    <warum-pm-section></warum-pm-section>

    <style>${css}</style>
        
`;

// Define the main page component
class Page extends HTMLElement {
    constructor(){
        super();
    }

    connectedCallback(){
        util.setPageTitle(pageTitle);
        util.setPageDescription(pageDescription);
        this.innerHTML = template;
    }
}

// Register the main page component
customElements.define(tagName, Page);

// Export the main component if needed
export default `<${tagName}></${tagName}>`;