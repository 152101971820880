const tagName = "feedback-box-component";

var feedbackCss = /*css*/`
    ${tagName} {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background: white;
        border-radius: 10px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
        padding: 15px;
        left: 12px;
        width: 410px;
        height: 200px;
        margin: 10px;
        position: relative;
    }

    ${tagName} .feedback-star {
        display: flex;
        gap: 2px;
        top: -10px;
    }

    ${tagName} .feedback-star img {
        width: 20px;
        height: 20px;
    }

    ${tagName} .feedback-google-logo {
        position: absolute;
        top: 17px;
        right: 15px;
        width: 20px;
        height: 20px;
    }

    ${tagName} .feedback-text {
        margin-top: 10px;
        font-size: 14px;
        line-height: 1.4;
        color: #333;
        max-height: 100px; /* Set the maximum height for the text area */
        overflow-y: hidden;  /* Hide overflow initially */
        position: relative;
    }

    ${tagName} .feedback-text.overflowing {
        overflow-y: auto;  /* Enable vertical scrolling if content exceeds max height */
        padding-right: 10px;
    }

    ${tagName} .feedback-ellipsis {
        position: absolute;
        font-weight: bold;
        bottom: 46px;
        left: 15px;
        background: linear-gradient(to top, white, transparent);
    }

    ${tagName} .feedback-user-name {
        font-weight: bold;
        font-size: 14px;
        margin-top: 20px;
    }

    @media (max-width: 1244px) {
        ${tagName} { 
        left: 6px;
        width: 290px;
        }
    }

    @media (max-width: 430px) {
        ${tagName} { 
            left: 6px;
        width: 290px;
        }
    }

    @media (max-width: 390px) {
        ${tagName} { 
            left: -7px;
        width: 290px;
        }
    }
`;

const renderFeedbackBox = (options) => {
    return /*html*/`
        <div class="feedback-box">
            <div class="feedback-star">
                ${[...Array(5)].map(_ => '<img src="/assets/images/star-icon.svg" alt="star">').join('')}
            </div>
            <img src="/assets/images/google_logo.png" class="feedback-google-logo" alt="Google Logo">
            <div class="feedback-text">${options.text}</div>
            <div class="feedback-user-name">${options.userName}</div>
            <div class="feedback-ellipsis">...</div>
            <style>${feedbackCss}</style>
        </div>
    `;
};

class FeedbackBoxComponent extends HTMLElement {
    constructor(){
        super();
    }

    connectedCallback(){
        this.options = JSON.parse(this.getAttribute('element-options'));
        this.innerHTML = renderFeedbackBox(this.options);

        // Check if the text overflows and needs scrolling
        const feedbackText = this.querySelector('.feedback-text');
        const feedbackEllipsis = this.querySelector('.feedback-ellipsis');

        if (feedbackText.scrollHeight > feedbackText.clientHeight) {
            feedbackText.classList.add('overflowing');
            feedbackEllipsis.style.display = 'block';
            feedbackText.addEventListener('scroll', () => {
                if (feedbackText.scrollTop > 0) {
                    feedbackEllipsis.style.display = 'none';
                } else {
                    feedbackEllipsis.style.display = 'block';
                }
            });
        } else {
            feedbackEllipsis.style.display = 'none';
        }
    }
}

customElements.define(tagName, FeedbackBoxComponent);

function renderFeedback(options){
    return `<${tagName} element-options='${JSON.stringify(options)}'></${tagName}>`;
}

export default renderFeedback;