window.eventBus = {};

eventBus.eventID = 0;

eventBus.events = {};

eventBus.register = (eventName, callBack)=>{
    eventBus.eventID++;
    eventBus.events[eventName] = eventBus.events[eventName] || [];
    var event = {
        id: eventBus.eventID,
        callBack,
        unregister: ()=>{
            eventBus.unregister(event.id)
        }
    };
    eventBus.events[eventName].push(event);
    return event;
};

eventBus.unregister = (eventID)=>{
    var eventNames = Object.keys(eventBus.events);
    var done = false;
    for(let i=0; i<eventNames.length; i++){
        var eventName = eventNames[i];
        var events = eventBus.events[eventName];
        for(let j=0; j<events.length; j++){
            var event = events[j];
            if(event.id == eventID){
                var newEvents = [];
                for(let k=0; k<events.length; k++){
                    if(k != j){
                        newEvents.push(events[k]);
                    }
                }
                eventBus.events[eventName] = newEvents;
                done = true;
                break
            }
        }
        if(done){break}
    }
};

eventBus.emit = (eventName, options={})=>{
    var events = eventBus.events[eventName];
    if(!events){return}
    events.forEach(event=>{
        event.callBack(options);
    });
};

export default eventBus;