import DoubleCTABox from '/components/double-cta-box.js'
import '/config.js'
import '/services/util.js'

const tagName = "about-page";

var pageTitle = "Über das Franklin Institute of Applied Sciences";
var pageDescription = "Das Franklin Institute bietet zu 100% staatliche geförderte IT-Weiterbildungen und Karriereunterstützung für Arbeitssuchende. Entdecke deine Möglichkeiten in der IT-Branche mit unserem umfassenden Kursangebot, erfahrenen Dozenten und individueller Beratung. Starte jetzt deine erfolgreiche IT-Karriere mit uns.";

var css = /*css*/`

`;

var template = /*html*/`
    <iframe id="test-frame" src="/legacy-website/frames/about_frame.html" frameborder="0" width="100%" scrolling="no" height="2000px" title="${pageTitle}"></iframe>
    <div class="double-cta-cont" style="padding: 3em 1rem; background: linear-gradient(to bottom, white 0%, white 50%, var(--gray-brand) 50%, var(--gray-brand) 100%);">
        ${DoubleCTABox({
            texts: [
                "Haben Sie Fragen?",
                "Wir beraten Sie gerne persönlich.",
                "Nutzen Sie hierfür einfach unser Bewerbungsformular oder rufen Sie uns direkt an."
            ],
            ctas: [
                {label: "Jetzt bewerben.", href: config.ctaURLS.main, internal: false, newTab: true},
                {label: "+49 89 62826149", href: "tel:+498962826149"}
            ]
        })}
    </div>
    <a cta-emulator href="${config.ctaURLS.main}" target="_blank" style="display: none;"></a>
    <style>${css}</style>
`;

class Page extends HTMLElement {
    constructor(){
        super();
    }

    connectedCallback(){
        util.setPageTitle(pageTitle);
        util.setPageDescription(pageDescription);
        this.innerHTML = template;
        this.iframeElem = this.querySelector('iframe');
        this.messageEvent = eventBus.register('message', (options)=>{
            this.handleIframeMessage(options);
        });
    }

    disconnectedCallback(){
        this.messageEvent.unregister();
    }

    handleIframeMessage(message){
        try{
            var msg = JSON.parse(message);
            if(msg.type == "height"){
                var setHeight = Math.floor(msg.data) + 5 + "px";
                this.iframeElem.height = setHeight;
            }
            if(msg.type == "cta"){
                this.querySelector('a[cta-emulator]').click();
            }
        }catch{}
    }
}

customElements.define(tagName, Page);

export default `<${tagName}></${tagName}>`