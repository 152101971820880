import HomePage from '../pages/home.js'
import NotFoundPage from '../pages/404.js'
import AboutPage from '../pages/about.js'
import CareerPage from '../pages/career.js'
import VermittlerPage from '../pages/vermittler.js'
import PartnerPage from '../pages/partner.js'
import PMCoursePage from '../pages/pm-course.js'
import SalesCoursePage from '../pages/sales-course.js'
import ImpressumPage from '../pages/impressum.js'
import DatenschutzPage from '../pages/datenschutz.js'
import '../services/event-bus.js'
// import Router from '../services/router.js'

const tagName = "page-cont";

const pages = {
    "/": HomePage,
    "/404": NotFoundPage,
    "/not-found.html": NotFoundPage,
    "/about": AboutPage,
    "/vermittler": VermittlerPage,
    "/career": CareerPage,
    "/courses/pm": PMCoursePage,
    "/courses/sales": SalesCoursePage,
    "/impressum": ImpressumPage,
    "/datenschutz": DatenschutzPage,
    "/partner": PartnerPage
};

var css = /*css*/`
    ${tagName} div.content-placeholder {
        font-size: 32px;
    }
    ${tagName} {
        display: block;
        padding-top: var(--nav-menu-height);
        width: 100%;
        overflow-x: hidden;
        background: white;
    }
    ${tagName} .blue-line {
        height: 0.5rem;
        background: var(--blue-wide-gradient);
    }
    ${tagName} .page-hero-small {
        font-size: 1.5rem;
        margin-bottom: 1em;
        font-family: 'inter-medium';
    }
    ${tagName} .page-hero-medium {
        font-size: clamp(1.6rem, 5vw, 2.2rem);
        margin-bottom: 1em;
        font-family: 'inter-bold';
    }
    ${tagName} .page-hero-large {
        font-size: 3rem;
        margin-bottom: 1em;
        font-family: 'inter-bold';
    }
    ${tagName} .centered {
        text-align: center;
    }
    ${tagName} div.line {
        display: inline-block;
    }
`;

var template = /*html*/`
    <div id="page-container"></div>
    <style>${css}</style>
`;

class PageCont extends HTMLElement {
    constructor(){
        super();
    }

    connectedCallback(){
        this.innerHTML = template;
        this.navigationEvent = eventBus.register('navigation', ()=>{
            this.handleNavigation();
        });
        this.pageContainer = this.querySelector('#page-container');
        this.handleNavigation();
    }

    handleNavigation(){
        var pageHTML = pages[location.pathname] || /*html*/`<div style="height: 1000px;"></div>`;
        this.pageContainer.innerHTML = pageHTML;
    }

    disconnectedCallback(){
        this.navigationEvent.unregister();
    }
}

customElements.define("page-cont", PageCont);

export default "<page-cont></page-cont>"