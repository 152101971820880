import MainMenu from '/components/main-menu.js'
import PageCont from '/sections/page-cont.js'
import Footer from '/sections/footer.js'
import CookieBanner from '/components/cookie-banner.js'
import '/config.js'
import '/services/util.js'

var template = /*html*/`
    <div class="background"></div>
    ${MainMenu(`Nächster Starttermin: ${util.formatDate(config.nextStartDate)}`)}
    ${PageCont}
    ${Footer}
    ${CookieBanner}
    <style>
        html, body {
            margin: 0;
            font-family: sans-serif;
        }
        app-cont > .background {
            position: fixed;
            top: -100%;
            left: -100%;
            right: -100%;
            bottom: 50%;
            background: white;
            z-index: -5;
        }
        app-cont a {
            width: fit-content;
        }
    </style>
`;

class AppCont extends HTMLElement {
    constructor(){
        super();
    }

    connectedCallback(){
        this.innerHTML = template;
    }
}

customElements.define("app-cont", AppCont);