const tagName = "feature-card";

var css = /*css*/`
    ${tagName} {
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 5px;
        padding: 15px 20px;
        background: rgba(242, 246, 247, 0.8);
        opacity: 1;
        color: rgb(70, 70, 70);
        cursor: grab;
        transition: 0.2s;
    }
    ${tagName} .feature-title {
        font-size: 24px;
        font-family: 'inter-bold';
        transition: 0.2s;
    }
    ${tagName} .feature-title:before {
        margin-right: 10px;
        display: inline-flex;
        width: 25px;
        transform: translateY(3px);
        aspect-ratio: 1 / 1;
        background-image: url(/assets/images/blue-arrow-double.svg);
        background-size: contain;
        background-position: 50% 50%;
        transform: rotate(-90deg) translateX(-3px);
        background-repeat: no-repeat;
        content: '';
        transition: 0.2s;
    }
    ${tagName} .feature-text {
        font-size: 14px;
        font-family: 'inter-light';
        width: 100%;
    }
    ${tagName}:hover {
        transform: translateY(-3px);
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.08);
    }
    ${tagName}:hover .feature-title:before {
        margin-right: 15px;
    }
`;

const renderInnerHTML = (options)=>{
    if(options.html){
        options.text = options.html;
    }
    return /*html*/`
        <div class="feature-title">${options.title}</div>
        <div class="feature-text">${options.text}</div>
        <style>${css}</style>
    `;
};

class CustomElem extends HTMLElement {
    constructor(){
        super();
    }

    connectedCallback(){
        var d = window.decodeURIComponent;
        this.options = JSON.parse(d(this.getAttribute('element-options')));
        this.innerHTML = renderInnerHTML(this.options);
        this.removeAttribute('element-options');
        // setTimeout(()=>{
        //     this.setAttribute('delayed-style', true);
        // }, 1);
    }
}

customElements.define(tagName, CustomElem);

function render(options){
    var e = window.encodeURIComponent;
    return `<${tagName} element-options="${e(JSON.stringify(options))}"></${tagName}>`;
}

export default render