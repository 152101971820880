import FeatureCard from '../components/feature-card.js'
import DoubleCTABox from '../components/double-cta-box.js'
import EmployerMarquee from '/sections/employer-marquee.js'
import HeroButton from '/components/hero-button.js'
import CourseCard from '/components/course-card.js'
import CheckmarkList from '/components/checkmark-list.js'
import FiasWhySectionVisual from '/sections/fias-why-section-visual.js'
import PartnerSection from '/sections/partner-section.js'
import HomeHeroSection from '/sections/home-hero-section.js'
import FAQBox from '/components/faq-box.js'
import '/config.js'
import '/services/util.js'
import FeedbackSection from '/sections/feedback-section.js'; // Import the FeedbackSection component

const tagName = "home-page";

var pageTitle = "Home | Franklin Institute of Applied Sciences";
var pageDescription = "Das Franklin Institute bietet zu 100% staatliche geförderte IT-Weiterbildungen und Karriereunterstützung für Arbeitssuchende. Entdecke deine Möglichkeiten in der IT-Branche mit unserem umfassenden Kursangebot, erfahrenen Dozenten und individueller Beratung. Starte jetzt deine erfolgreiche IT-Karriere mit uns.";

const j = JSON.stringify;

var css = /*css*/`
    ${tagName} {
        font-size: clamp(16px, 1.8vw, 18px);
    }
    ${tagName} triplecolumn {
        display: flex;
        background: red;
    }
    ${tagName} .flex-col-center {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    ${tagName} .two-col-wrapper {
        margin-bottom: 3em;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    ${tagName} .blue-line {
        height: 0.5em;
        background: var(--blue-wide-gradient);
    }
    ${tagName} .hero-1 {
        font-family: 'inter-bold', sans-serif;
        font-size: 2em;
        margin-bottom: 0.5em;
    }
    ${tagName} .hero-2 {
        font-family: 'inter-bold', sans-serif;
        font-size: 1.6em;
        margin-bottom: 0.5em;
    }
    ${tagName} .grow-font-2 {
        font-size: clamp(32px, 4.18vw, 46px) !important;
    }
    ${tagName} .section-dream-career .two-col-wrapper {
        margin-top: 3em;
        margin-bottom: 2em;
        gap: 4em;
    }
    @media (min-width: 700px) {
        ${tagName} .section-dream-career .two-col-wrapper {
            flex-direction: column;
        }
    }
    @media (min-width: 900px) {
        ${tagName} .section-dream-career .two-col-wrapper {
            flex-direction: row;
        }
    }
    ${tagName} .dream-career-img {
        display: block;
        width: 320px;
        aspect-ratio: 633/948;
        background-image: url('/assets/images-webp/dream-career-img.webp');
        border-radius: 0.7em;
    }
    ${tagName} .hero-3 {
        font-family: 'inter-medium', sans-serif;
        font-size: 1.2em;
        margin-bottom: 0.5em;
    }
    ${tagName} .section-dream-career .hero-3 {
        font-family: 'inter-bold', sans-serif;
    }
    ${tagName} .section-dream-career .paragraph {
        margin-bottom: 0;
    }
    ${tagName} .paragraph {
        margin-bottom: 2em;
        line-height: 1.6em;
        font-family: 'inter-medium', sans-serif;
    }
    ${tagName} .numbered-list-item {
        display: flex;
    }
    ${tagName} .section-dream-career .two-col-cont b {
        font-family: 'inter-bold', sans-serif;
    }
    ${tagName} .numbered-list-item-number {
        font-size: 45px;
        margin-right: 45px;
        margin-left: 35px;
        position: relative;
        z-index: 1;
        font-family: 'inter-medium', sans-serif;
    }
    ${tagName} .numbered-list-item-number:before {
        position: absolute;
        left: -40px;
        top: -10px;
        content: '';
        width: 90px;
        height: 90px;
        z-index: -1;
        background-image: url('/assets/images/number-background.svg');
        background-size: 100% 100%;
    }
    ${tagName} .limit-max-width {
        max-width: 85%;
        margin-left: auto;
        margin-right: auto;
    }
    @media (max-width: 1100px) {
        ${tagName} .limit-max-width {
            max-width: 95%;
        }
    }
    @media (max-width: 459px) {
        ${tagName} .numbered-list-item-number {
            display: none;
        }
        ${tagName} .numbered-list-item .hero-3:before {
            content: attr(data-num);
            margin-right: 0.3em;
        }
    }
    ${tagName} .course-card-cont {
        display: flex;
        gap: 2em;
        margin: 0 auto;
        width: 100%;
        justify-content: center;
        flex-wrap: wrap;
    }
    ${tagName} .course-card-cont course-card {
        width: 450px;
    }
    @media (max-width: 490px) {
        ${tagName} .course-card-cont {
            gap: 1em;
        }
    }
    ${tagName} .course-card-title {
        font-family: 'inter-bold';
        font-size: clamp(1.5rem, 9.375vw, 3rem);
        text-align: center;
        margin-bottom: 0.8em;
    }
`;

var template = /*html*/`
    <div class="wrapper">
        ${HomeHeroSection}
    </div>
    ${EmployerMarquee}
    <div style="width: 100%; height: 70px;"></div>
    <div class="wrapper">
        <div class="course-card-title" id="courses">Unsere Weiterbildungen</div>
        <div class="course-card-cont">
            ${CourseCard({
                title: "IT-Berater<br>im Produkt-<br>management",
                bulletPoints: [
                    "Inkl. Karrierecoaching",
                    "100% finanziert durch die Agentur für Arbeit",
                    "Inkl. MacBook",
                    //`${config.courseData.pm.duration} Monate Dauer`,
                    `${config.courseData.pm.minCertCount} anerkannte Zertifikate + IHK Zertifikat`
                ],
                imageURL: "/assets/images-webp/course-banner-img-pm.webp",
                ctaURL: "/courses/pm"
            })}
            ${CourseCard({
                title: "IT-Sales<br>Manager &<br>Solution<br>Architect",
                bulletPoints: [
                    "Inkl. Karrierecoaching",
                    "100% finanziert durch die Agentur für Arbeit",
                    "Inkl. MacBook",
                    //`${config.courseData.sales.duration} Monate Dauer`,
                    `${config.courseData.sales.minCertCount} anerkannte Zertifikate + IHK Zertifikat`
                ],
                imageURL: "/assets/images-webp/course-banner-img-sales.webp",
                ctaURL: "/courses/sales"
            })}
        </div>
    </div>
    <div style="width: 100%; height: 70px;"></div>
    <div class="section section-dream-career">
        <div class="wrapper">
            <div class="flex-col-center">
                <div class="hero-1 grow-font-2"> Und so funktionierts</div>
                <div class="hero-2" style="color: var(--blue-brand);">In vier einfachen Schritten zur Traumkarriere</div>
            </div>
            <div class="two-col-wrapper limit-max-width">
                <div class="two-col-cont" style="flex: 0; display: flex; justify-content: center; align-items: center;">
                    <div style="display: flex;">
                        <div class="dream-career-img bg-img-cover"></div>
                    </div>
                </div>
                <div class="two-col-cont list-col" style="flex: 1;">
                    <div style="display: flex; flex-direction: column; gap: 2rem;">
                        <div class="numbered-list-item" data-num="1">
                            <div class="numbered-list-item-number">
                                1
                            </div>
                            <div class="numbered-list-item-text">
                                <div class="hero-3" data-num="1.">Fülle unsere Kurzbewerbung aus.</div>
                                <div class="paragraph">
                                    Über den Button gelangst Du zu unserem Bewerbungsformular. Nachdem du dieses ausgefüllt hast setzen wir uns mit dir in Verbindung und sprechen mit dir über deine Bewerbung.
                                </div>
                            </div>
                        </div>
                        <div class="numbered-list-item" data-num="2">
                            <div class="numbered-list-item-number">
                                2
                            </div>
                            <div class="numbered-list-item-text">
                                <div class="hero-3" data-num="2.">Bildungsgutschein sichern</div>
                                <div class="paragraph">
                                    Wir helfen dir dabei einen <b>Bildungsgutschein</b> bei der Agentur für Arbeit oder beim Jobcenter zu beantragen. So ist deine Weiterbildung bei uns zu <b>100% kostenlos.</b> 
                                </div>
                            </div>
                        </div>
                        <div class="numbered-list-item" data-num="3">
                            <div class="numbered-list-item-number">
                                3
                            </div>
                            <div class="numbered-list-item-text">
                                <div class="hero-3" data-num="3.">Gemeinsam lernen - Profi werden</div>
                                <div class="paragraph">
                                    <b>Du erhältst einen Laptop</b> von uns und dein Weg in eine <b>erfolgreiche IT-Karriere</b> kann beginnen! 
                                </div>
                            </div>
                        </div>
                        <div class="numbered-list-item" data-num="4">
                            <div class="numbered-list-item-number">
                                4
                            </div>
                            <div class="numbered-list-item-text">
                                <div class="hero-3" data-num="4.">Der Einstieg in deine Karriere</div>
                                <div class="paragraph">
                                    Mit unserem <b>Bewerbungs- und Karriere Coaching</b> helfen wir dir kostenlos dabei deinen <b>Traumjob</b> zu finden! 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="section" style="background: var(--gray-brand);">
        <div class="blue-line"></div>
        <div class="wrapper" style="padding: 2rem 0;">
            <div class="flex-col-center">
                <div class="hero-1" style="margin-bottom: 1em; color: white; text-align: center;">Finde jetzt heraus, ob Du zu uns passt!</div>
                <a cta-prevent-inner-hover target="_blank" href="${config.ctaURLS.main}" class="menu-desktop">${HeroButton(/*html*/`<span>Jetzt bewerben</span>`)}</a>
            </div>
        </div>
        <div class="blue-line"></div>
    </div>
    <style>
        ${tagName} .section-why {
            display: flex;
            justify-content: center;
            margin-left: auto;
            margin-right: auto;
            margin-top: 3rem;
        }
        ${tagName} .section-why .two-col-wrapper {
            display: flex;
            justify-content: center;
        }
        ${tagName} checkbox-list {
            flex: 1.2;
        }
        ${tagName} fias-why-section-visual {
            flex: 1;
        }
        ${tagName} .section-why-main-cont {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 3em;
            margin-top: 2.5em;
            padding: 0 3em;
        }
        @media(max-width: 1100px){
            ${tagName} .section-why-main-cont {
                gap: 2em;
                padding: 0 1em;
            }
            ${tagName} checkbox-list {
                flex: 1.3;
            }
            ${tagName} fias-why-section-visual {
                flex: 1;
            }
        }
        @media(max-width: 899px){
            ${tagName} .section-why-main-cont {
                flex-direction: column-reverse;
                max-width: 500px;
                margin-top: 1.5em;
            }
            ${tagName} .section-why {
                max-width: 500px;
            }
            ${tagName} checkbox-list {
                flex: initial;
            }
            ${tagName} fias-why-section-visual {
                flex: initial;
            }
        }
        @media(max-width: 400px){
            ${tagName} .section-why-main-cont {
                gap: 2em;
                padding: 0 0em;
            }
        }
    </style>
    <div class="section section-why">
        <div class="wrapper">
            <div class="flex-col-center">
                <div class="hero-1 grow-font-2">Warum das Franklin Institute?</div>
                <div class="hero-2" style="color: var(--blue-brand);">Profitiere von unserem einzigartigen Weiterbildungs-System</div>
            </div>
            <div class="section-why-main-cont">
                ${FiasWhySectionVisual}
                ${CheckmarkList([
                    {
                        title: "Zeitaufwand auf das mindeste reduziert!",
                        content: "Unsere Weiterbildung soll <b>in Dein Leben passen</b> und so schnell wie möglich dabei helfen, dass Du Deinen Weg in <b>Deine Traumkarriere</b> findest."
                    },
                    {
                        title: "100% Kostenlos - mit Bildungsgutschein",
                        content: "Bildung sollte bezahlbar, oder noch besser, <b>kostenlos</b> sein. Unsere Weiterbildung kannst Du <b>mit dem Bildungsgutschein komplett kostenlos absolvieren</b>. Bewerbe Dich einfach bei uns, <b>wir helfen Dir</b>, den Bildungsgutschein zu erhalten."
                    },
                    {
                        title: "Individuelle Betreuung - lernen in kleinen Gruppen",
                        content: "Damit Du in unserem einjährigen Karriereprogramm optimal auf Deinen neuen Job vorbereitet wirst, setzen wir auf <b>kleine, homogene</b> Lerngruppen."
                    },
                    {
                        title: "Unschlagbares Karriere-Programm für deinen Traumjob",
                        content: "Aufgrund der langen Erfahrung und der verschiedenen Karrierewege unseres Gründerteams verfügen wir über einen <b>unbezahlbaren Wissensschatz aus über 50 Jahren erfolgreicher Karrieren</b> in verschiedensten Bereichen. <b>Dieses Wissen wollen wir mit dir teilen, ohne Aufpreis, ohne Verpflichtungen.</b> Deshalb haben wir unser <b>Karriere- und Bewerbungscoaching</b> entwickelt um dir am Ende deiner Ausbildung das nötige Wissen für einen <b>Raketenstart in deine eigene erfolgreiche Karriere</b> zu geben! Freu dich darauf, das ist der <b>wohl wichtigste Teil deiner Weiterbildung beim Franklin Institute.</b>"
                    }
                ])}
            </div>
        </div>
    </div>
    <div class="section" style="margin-top: 1rem;">
        <div class="wrapper">
            ${PartnerSection}
        </div>
       ${FeedbackSection} <!-- Insert the FeedbackSection here -->

    </div>
    <div class="section" style="margin: 1rem 0;">
        <div class="wrapper">
            <div class="hero-1" style="text-align: center; margin-bottom: 3rem; margin-top: 2rem;" id="faq">Häufig gestellte Fragen:</div>
            ${FAQBox([
                {
                    q: "Was bringt mir eine Weiterbildung beim Franklin Institute?",
                    a: "Wir leben Karriere. Das ist unser Motto - Unsere Weiterbildungen sollen Dir genau das ermöglichen - Karriere. Wir bilden Dich über mehrere Monate in einem relevanten Beruf in der IT-Branche aus und unterstützen Dich dann beim Einstieg in deine Traumkarriere. Mit uns an Deiner Seite formen wir Deine Karriere und geben Dir alles an die Hand, was Du brauchst um beruflich erfolgreich zu sein."
                },
                {
                    q: "Welche Voraussetzungen muss ich erfüllen?",
                    a: "Um an der Weiterbildung & am Career Success Programm teilnehmen zu können, musst Du lediglich (bald) bei der Agentur für Arbeit oder beim Jobcenter arbeitslos gemeldet sein. Solltest Du dies nicht sein, ist die Teilnahme leider nicht möglich. Du wirst in absehbarer Zeit arbeitslos? Dann bewerbe Dich gerne über unser kurzes Formular bei uns."
                },
                {
                    q: "Fallen für die Weiterbildung Kosten an?",
                    a: "Wenn Du arbeitslos gemeldet bist, hast Du die Möglichkeit einen Bildungsgutschein vom Staat zu erhalten. Dieser Bildungsgutschein finanziert Dir die komplette Weiterbildung und unser Career Success Programm - somit ist unser gesamtes Angebot kostenlos für Dich. Bewerbe Dich gerne über unser kurzes Formular und wir helfen Dir dabei einen Bildungsgutschein zu erhalten."
                },
                {
                    q: "Kann ich die Weiterbildung von Zuhause aus absolvieren?",
                    a: "Ja - die komplette Weiterbildung inkl. Career Success Programm findet Remote statt. Somit hast Du die Möglichkeit von überall aus teilzunehmen. Du erhältst auch einen Laptop von uns."
                },
                {
                    q: "Erhalte ich nach meiner Weiterbildung ein anerkanntes Zertifikat?",
                    a: "Ja, da wir AZAV zertifiziert sind, erhältst du nach deiner Weiterbildung ein offizielles Abschluss-Zertifikat von uns. Zusätzlich erhältst du während unseren Weiterbildungen einige, in der IT-Branche sehr angesehene, Zertifikate von namhaften Anbietern. So stellen wir sicher, dass du am Ende neben deiner Praxiserfahrung auch die nötigen Dokumente für einen erfolgreichen Einstieg in die IT-Branche vorlegen kannst."
                }
            ])}
        </div>
    </div>
    <div class="double-cta-cont" style="padding: 3em 1rem; background: linear-gradient(to bottom, white 0%, white 50%, var(--gray-brand) 50%, var(--gray-brand) 100%);">
        ${DoubleCTABox({
            texts: [
                "Haben Sie Fragen?",
                "Wir beraten Sie gerne persönlich.",
                "Nutzen Sie hierfür einfach unser Bewerbungsformular oder rufen Sie uns direkt an."
            ],
            ctas: [
                {label: "Jetzt bewerben.", href: config.ctaURLS.main, internal: false, newTab: true},
                {label: "+49 89 62826149", href: "tel:+498962826149"}
            ]
        })}
    </div>
    <style>${css}</style>
`;

//<feature-card element-options='${j({title: "MacBook inklusive", html: '<img src="https://franklin-institute.de/images-webp/hero-img.webp"/>Hallo'})}'/></feature-card>

class Page extends HTMLElement {
    constructor(){
        super();
    }

    connectedCallback(){
        util.setPageTitle(pageTitle);
        util.setPageDescription(pageDescription);
        this.innerHTML = template;
    }
}

customElements.define(tagName, Page);

export default `<${tagName}></${tagName}>`