const tagName = "cookie-banner";

const consentCookieName = "cookie-consent-state";

var config = {
    title: "Datenschutzeinstellungen",
    textHTML: `
        Wir nutzen Cookies und andere Technologien, um Inhalte und Anzeigen zu personalisieren und dir die bestmögliche Nutzung unserer Webseite zu ermöglichen. Die mit Hilfe von Cookies gesammelten Daten werden zur Optimierung der Webseite genutzt. Natürlich kannst du deine Entscheidung jederzeit widerrufen oder ändern. Weitere Informationen findest du in der <a internal href="/datenschutz">Datenschutzerklärung</a>.
    `
};

var css = /*css*/`
    ${tagName} {
        display: none;
        position: fixed;
        bottom: 0;
        background: white;
        width: 100%;
        font-size: 1rem;
        // box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
        z-index: 10001;
    }
    ${tagName} .cookie-buttons {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 0.5em;
        min-width: 14em;
    }
    ${tagName} button {
        border: none;
        outline: none;
        background: transparent;
        cursor: pointer;
        width: 100%;
        padding: 14px 12px;
        font-family: 'inter-medium', 'sans-serif';
        border-radius: 0.3em;
        box-sizing: border-box;
        font-size: 1.1em;
        white-space: nowrap;
    }
    ${tagName} .cookie-title {
        font-family: 'inter-bold';
        margin-bottom: 0.5em;
        font-size: 1.2em;
    }
    ${tagName} .cookie-text {
        font-size: 0.9em;
        // margin-bottom: 1em;
        line-height: 1.5em;
    }
    ${tagName} .cookie-text a {
        text-decoration: underline;
    }
    ${tagName} #cookie-accept {
        background: var(--gray-brand-gradient);
        // border: 2px solid var(--gray-brand);
        padding: 16px 10px;
        color: white;
        transition: color 300ms;
    }
    ${tagName} #cookie-accept:hover {
        color: var(--blue-brand);
    }
    ${tagName} #cookie-accept span {
        border-bottom: 2px solid var(--blue-brand);
    }
    ${tagName} #cookie-decline {
        // border: 2px solid var(--gray-brand);
        padding: 16px 12px;
        transition: background-color 300ms;
    }
    ${tagName} #cookie-decline:hover {
        background: rgba(0, 0, 0, 0.05);
    }
    ${tagName} .inner {
        max-width: 1920px;
        margin: 0 auto;
        padding: 1em;
        gap: 1em;
        align-items: center;
        font-family: 'inter-light', 'sans-serif';
        display: flex;
    }
    ${tagName} .line {
        height: 0.5rem;
        background: white;
        background: var(--blue-wide-gradient);
        background: var(--light-blue-gradient);
    }
    @media(max-width: 768px){
        ${tagName} .inner {
            flex-direction: column;
        }
        ${tagName} .cookie-buttons {
            width: 100%;
        }
    }
`;

const renderInnerHTML = ()=>{
    return /*html*/`
        <div class="line"></div>
        <div class="inner">
            <div class="cookie-text-cont">
                <div class="cookie-title">${config.title}</div>
                <div class="cookie-text">${config.textHTML}</div>
            </div>
            <div class="cookie-buttons">
                <button id="cookie-accept"><span>Alle akzeptieren</span></button>
                <button id="cookie-decline">Nur notwendige</button>
            </div>
        </div>
        <style>${css}</style>
    `;
};

class CustomElem extends HTMLElement {
    constructor(){
        super();
    }

    connectedCallback(){
        this.innerHTML = renderInnerHTML();
        this.acceptBtn = this.querySelector('#cookie-accept');
        this.acceptBtn.onclick = ()=>{this.accept();};
        this.declineBtn = this.querySelector('#cookie-decline');
        this.declineBtn.onclick = ()=>{this.decline();};
        this.showBannerEvent = eventBus.register('show-cookie-banner', ()=>{
            this.show();
        });
        var currentConsentState = this.readConsentCookie();
        if(!currentConsentState){
            this.show();
        }else{
            if(currentConsentState.marketing){
                this.accept();
            }else{
                this.decline();
            }
        }
    }

    disconnectedCallback(){
        this.showBannerEvent.unregister();
    }

    readConsentCookie(){
        var cookieString = document.cookie;
        if(!cookieString){return null}
        var cookies = cookieString.split(";");
        for(let i=0; i<cookies.length; i++){
            var c = cookies[i].trim();
            if(c.includes("=")){
                var name = c.split("=")[0].trim();
                var key = c.split("=")[1];
                if(name == consentCookieName){
                    try{
                        var decodedKey = decodeURIComponent(key);
                        var res = JSON.parse(decodedKey);
                        return res;
                    }catch(e){
                        return null
                    }
                }
            }
        }
    }

    show(){
        document.body.setAttribute('cookie-banner-visible', 'true');
        this.style.display = "block";
        document.documentElement.style.setProperty('--cookie-banner-height', `${this.getBoundingClientRect().height}px`);
    }

    hide(){
        document.body.setAttribute('cookie-banner-visible', 'false');
        this.style.display = "none";
        document.documentElement.style.setProperty('--cookie-banner-height', `0px`);
    }

    accept(){
        this.hide();
        this.setConsentCookie(true);
        try{
            fbq('consent', 'grant');
            ttq.grantConsent();
            ttq.enableCookie();
        }catch{}
    }

    decline(){
        this.hide();
        this.setConsentCookie(false);
        try{
            fbq('consent', 'revoke');
            ttq.revokeConsent();
            ttq.disableCookie();
        }catch{}
    }

    setConsentCookie(consent){
        var useCookieValue = encodeURIComponent(JSON.stringify({
            necessary: true,
            marketing: consent,
            statistics: consent
        }));
        document.cookie = `${consentCookieName}=${useCookieValue}; path=/`;
    }
}

customElements.define(tagName, CustomElem);

export default `<${tagName}></${tagName}>`