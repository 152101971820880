const tagName = "hero-button";

var css = /*css*/`
    ${tagName} {
        padding: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: var(--light-blue-gradient);
        border-radius: 10px;
        color: white;
        width: fit-content;
    }
    ${tagName}[no-border] {
        padding: 0px;
    }
    ${tagName}[use-shadow] {
        box-shadow: 0 0.6em 0.8em rgba(0, 0, 0, 0.2);
    }
    ${tagName} .inner {
        padding: 0.9em 1.8em;
        background: var(--gray-brand-gradient);
        border-radius: 5px;
        transition: 200ms;
    }
    body.has-hover a:hover ${tagName} .inner {
        opacity: 0.95;
    }
    ${tagName} .text {
        display: block;
        font-family: 'inter-medium', 'sans-serif';
        text-align: center;
        transition: 200ms;
    }
    ${tagName} .text span {
        border-bottom: 2px solid var(--blue-brand);
    }
    body.has-hover a:hover ${tagName} .text {
        transform: translateY(-0.05em);
        color: rgb(148, 231, 243);
    }
    body.has-hover a:hover ${tagName} .text span{
        color: rgb(148, 231, 243);
    }
    body.has-hover a:hover ${tagName} .text span {
        border-bottom: 2px solid rgb(148, 231, 243);
    }
`;

const renderInnerHTML = (options)=>{
    return /*html*/`
        <div class="inner">
            <span class="text">${options.text}</span>
        </div>
        <style>${css}</style>
    `;
};

class CustomElem extends HTMLElement {
    constructor(){
        super();
    }

    connectedCallback(){
        var d = window.decodeURIComponent;
        this.options = JSON.parse(d(this.getAttribute('element-options')));
        this.innerHTML = renderInnerHTML(this.options);
        if(this.options.noBorder){
            this.setAttribute('no-border', '');
        }
        if(this.options.shadow){
            this.setAttribute('use-shadow', '');
        }
        this.removeAttribute('element-options');
        // setTimeout(()=>{
        //     this.setAttribute('delayed-style', true);
        // }, 1);
    }
}

customElements.define(tagName, CustomElem);

function render(text, options){
    var useOptions = {text};
    if(options){
        Object.keys(options).forEach(optionKey=>{
            useOptions[optionKey] = options[optionKey];
        });
    }
    var e = window.encodeURIComponent;
    return `<${tagName} element-options="${e(JSON.stringify(useOptions))}"></${tagName}>`;
}

export default render