import HeroButton from '/components/hero-button.js'
import '/config.js'

const tagName = "start-date-cta-section";

var css = /*css*/`
    ${tagName} {
        display: block;
    }
    ${tagName} {
        font-size: clamp(0.8rem, 4.3vw, 1rem) !important;
    }
    ${tagName} .main-cont {
        display: block;
        background: var(--gray-brand);
        color: white;
    }
    ${tagName} .wrapper {
        display: flex;
        flex-direction: column;
        // justify-content: center;
        align-items: center;
        padding: 2em 0;
    }
    ${tagName} .section-message {
        max-width: 800px;
    }
    ${tagName} .section-message span {
        text-decoration: underline;
        text-decoration-color: var(--blue-brand);
        text-decoration-thickness: 0.2em;
    }
    ${tagName} .section-inner-box {
        box-sizing: border-box;
        margin: 0;
        margin-bottom: 2rem;
        margin-left: auto;
        margin-right: auto;
        padding: 1.5em 1em;
        border-radius: 0.7em;
        box-shadow: 0px 1em 2em rgba(0, 0, 0, 0.2);
        background: var(--light-blue-gradient);
        color: var(--gray-brand);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        max-width: 600px;
    }
    ${tagName} .section-box-title {
        word-break: break-word;
        font-family: 'inter-bold', sans-serif;
        font-size: 2.5em;
        margin-bottom: 0.5em;
    }
    ${tagName} .section-date-cont {
        margin-bottom: 1.5rem;
        font-family: 'inter-bold', sans-serif;
        font-size: 1.6em;
    }
    ${tagName} .section-date-cont:before {
        content: '';
        display: inline-block;
        width: 1.2em;
        height: 1.2em;
        background-image: url('/assets/images/calendar-gray.svg');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        transform: translateY(0.2em) translateX(-0.5em);
    }
    ${tagName} .above-cont {
        display: flex;
        justify-content: center;
        gap: 2em;
        align-items: center;
        margin-top: 3.5em;
        margin-bottom: 1.2em;
    }
    ${tagName} .above-cont-text {
        text-transform: uppercase;
        text-align: center;
        font-family: 'inter-bold', sans-serif;
        font-size: 1.6em;
    }
    ${tagName} .above-cont-text span {
        color: var(--blue-brand);
    }
    ${tagName} .double-arrow-down {
        width: 2.5em;
        height: 2.5em;
        background-image: url('/assets/images/blue-arrow-double.svg');
        background-size: 100% 100%;
    }
    ${tagName} .font-scale-down {
        font-size: clamp(1.5rem, 9.5vw, 2.2rem) !important;
    }
`;

const renderInnerHTML = (options)=>{
    var slug = options.courseSlug;
    var dateWritten = "";
    if(slug == "main"){
        dateWritten = util.formatDateWritten(config.nextStardDate);
    }else{
        dateWritten = util.formatDateWritten(config.courseData[slug].nextStartDate);
    }
    return /*html*/`
        <div class="above-cont">
            <div class="double-arrow-down"></div>
            <div class="above-cont-text">Jetzt<br><span>Bewerben</span></div>
            <div class="double-arrow-down"></div>
        </div>
        <div class="main-cont">
            <div class="blue-line"></div>
            <div class="wrapper">
                <div class="section-inner-box">
                    <div class="section-box-title">Nächster Starttermin:</div>
                    <div class="section-date-cont">${dateWritten}</div>
                    <a cta-prevent-inner-hover target="_blank" href="${config.ctaURLS[slug]}">${HeroButton(/*html*/`<span>Jetzt bewerben</span>`, {noBorder: true, shadow: true})}</a>
                </div>
                <div class="section-message page-hero-medium centered font-scale-down" style="margin-bottom: 0;">
                    <span>100%ige Kostenübernahme</span> durch die Agentur für Arbeit - mit dem Bildungsgutschein.
                </div>
            </div>
            <div class="blue-line"></div>
        </div>
        <style>${css}</style>
    `;
};

class CustomElem extends HTMLElement {
    constructor(){
        super();
    }

    connectedCallback(){
        var d = window.decodeURIComponent;
        this.options = JSON.parse(d(this.getAttribute('element-options')));
        this.innerHTML = renderInnerHTML(this.options);
        this.style.backgroundImage = `url('${this.options.imageURL}')`;
        this.removeAttribute('element-options');
    }
}

customElements.define(tagName, CustomElem);

function render(options){
    var e = window.encodeURIComponent;
    return `<${tagName} element-options="${e(JSON.stringify(options))}"></${tagName}>`;
}

export default render