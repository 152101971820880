import '/services/util.js'

const tagName = "career-page";

var pageTitle = "Karriere beim Franklin Institute | Traumjob finden und durchstarten";
var pageDescription = "Entdecke spannende Karrieremöglichkeiten beim Franklin Institute of Applied Sciences. Werde Teil unseres dynamischen Teams, nutze vielseitige Entwicklungschancen und arbeite mit inspirierenden Kollegen. Jetzt bewerben und deine berufliche Zukunft gestalten!";

var css = /*css*/`

`;

var template = /*html*/`
    <iframe id="test-frame" src="/legacy-website/frames/karriere_frame.html" frameborder="0" width="100%" scrolling="no" height="2000px" title="${pageTitle}"></iframe>
    <style>${css}</style>
`;

class Page extends HTMLElement {
    constructor(){
        super();
    }

    connectedCallback(){
        util.setPageTitle(pageTitle);
        util.setPageDescription(pageDescription);
        this.innerHTML = template;
        this.iframeElem = this.querySelector('iframe');
        this.messageEvent = eventBus.register('message', (options)=>{
            this.handleIframeMessage(options);
        });
    }

    disconnectedCallback(){
        this.messageEvent.unregister();
    }

    handleIframeMessage(message){
        try{
            var msg = JSON.parse(message);
            if(msg.type == "height"){
                var setHeight = Math.floor(msg.data) + 5 + "px";
                this.iframeElem.height = setHeight;
            }
            if(msg.type == "cta"){
                this.querySelector('a[cta-emulator]').click();
            }
        }catch{}
    }
}

customElements.define(tagName, Page);

export default `<${tagName}></${tagName}>`