const tagName = "fias-why-section-visual";

var css = /*css*/`
    ${tagName} {
        display: flex;
        flex-direction: column;
        gap: 8px;
        width: 100%;
        max-width: 500px;
    }
    ${tagName} .img {
        background-size: 100% 100%;
        border-radius: 0.7rem;
    }
    ${tagName} .portrait-cont {
        width: 100%;
        display: flex;
        gap: 8px;
    }
    ${tagName} .main-visual {
        width: 100%;
        aspect-ratio: 1 / 0.84968;
        background-image: url('/assets/images-webp/why-main-img.webp');
    }
    ${tagName} .slogan-cont {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        aspect-ratio: 4.598 / 1;
        background: var(--gray-brand-gradient);
        color: white;
        border-radius: 0.7rem;
    }
    ${tagName} .slogan {
        font-family: 'inter-bold', sans-serif;
        font-size: clamp(1rem, 6vw, 1.9rem);
    }
    ${tagName} .slogan span {
        color: var(--blue-brand);
    }
    ${tagName} .portrait {
        flex-grow: 1;
        aspect-ratio: 1 / 1.2971;
    }
    ${tagName} .portrait-1 {
        background-image: url('/assets/images/portrait-1.jpg');
    }
    ${tagName} .portrait-2 {
        background-image: url('/assets/images/portrait-2.jpg');
    }
    ${tagName} .portrait-3 {
        background-image: url('/assets/images/portrait-3.jpg');
    }
    ${tagName} .portrait-4 {
        background-image: url('/assets/images/portrait-burzin-new.jpg');
    }
    ${tagName} .portrait-5 {
        background-image: url('/assets/images/portrait-5.jpg');
    }
    ${tagName} .portrait-6 {
        background-image: url('/assets/images/portrait-6\(new\).jpg');
    }
    ${tagName} .portrait-7 {
        background-image: url('/assets/images/portrait-7\(new\).jpg');
    }
    ${tagName} .portrait-8 {
        background-image: url('/assets/images/portrait-8\(new\).jpg');
    }
    ${tagName} .portrait-9 {
        background-image: url('/assets/images/portrait-9\(new\).jpg');
    }
`;

var template = /*html*/`
    <div class="main-visual lazy-load-img-main-visual img img-main-visual appear-element"></div>
    <div class="portrait-cont">
        <div class="portrait lazy-load-portrait-1 img portrait-1 appear-element"></div>
        <div class="portrait lazy-load-portrait-3 img portrait-3 appear-element"></div>
        <div class="portrait lazy-load-portrait-4 img portrait-4 appear-element"></div>
    </div>
    <div class="portrait-cont">
        <div class="portrait lazy-load-portrait-6 img portrait-6 appear-element"></div>
        <div class="portrait lazy-load-portrait-7 img portrait-7 appear-element"></div>
        <div class="portrait lazy-load-portrait-8 img portrait-8 appear-element"></div>
        <div class="portrait lazy-load-portrait-9 img portrait-9 appear-element"></div>
    </div>
    <div class="slogan-cont">
        <div class="slogan">
            Wir leben <span>Karriere.</span>
        </div>
    </div>
    <style>
        ${css}
    </style>
`;

class CustomElem extends HTMLElement {
    constructor(options){
        super();
    }

    connectedCallback(){
        this.innerHTML = template;
    }
}

customElements.define(tagName, CustomElem);

export default `<${tagName}></${tagName}>`