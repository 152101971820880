import HeroButton from '/components/hero-button.js'

const tagName = "wide-cta-section";

var css = /*css*/`
    ${tagName} {
        display: block;
        background: var(--gray-brand);
        color: white;
    }
    ${tagName} .wrapper {
        display: flex;
        flex-direction: column;
        // justify-content: center;
        align-items: center;
        padding: 2em 0;
    }
    ${tagName} .section-message span {
        text-decoration: underline;
        text-decoration-color: var(--blue-brand);
        text-decoration-thickness: 0.2em;
    }
`;

const renderInnerHTML = (options)=>{
    var slug = options.courseSlug;
    return /*html*/`
        <div class="blue-line"></div>
        <div class="wrapper">
            <div class="section-message page-hero-medium centered">${options.title}</div>
            <a cta-prevent-inner-hover target="_blank" href="${config.ctaURLS[slug]}">${HeroButton(options.btnText)}</a>
        </div>
        <div class="blue-line"></div>
        <style>${css}</style>
    `;
};

class CustomElem extends HTMLElement {
    constructor(){
        super();
    }

    connectedCallback(){
        var d = window.decodeURIComponent;
        this.options = JSON.parse(d(this.getAttribute('element-options')));
        this.innerHTML = renderInnerHTML(this.options);
        this.style.backgroundImage = `url('${this.options.imageURL}')`;
        this.removeAttribute('element-options');
    }
}

customElements.define(tagName, CustomElem);

function render(options){
    var e = window.encodeURIComponent;
    return `<${tagName} element-options="${e(JSON.stringify(options))}"></${tagName}>`;
}

export default render