import HeroButton from '/components/hero-button.js'
import '/config.js'

const tagName = "home-hero-section";

var css = /*css*/`
    ${tagName} {
        display: flex;
        gap: 0;
        max-width: 990px;
        margin: 0 auto;
        padding-top: 4rem;
        overflow: visible;
        font-size: 1.2rem;
        padding-right: 1rem;
    }
    ${tagName} .hero-img-cont {
        // background: rgba(255, 0, 0, 0.1);
        flex: 2.5;
        align-self: stretch;
        position: relative;
        min-width: 380px;
    }
    ${tagName} .hero-img-inner-cont {
        width: 100%;
        aspect-ratio: 402 / 557;
        position: absolute;
        bottom: 0;
    }
    ${tagName} .hero-img {
        position: relative;
        width: 100%;
        height: 100%;
        background-image: url('/assets/images-webp/fias-hero-img-transparent-new.webp');
        background-size: 105%;
        background-repeat: no-repeat;
        background-position: 150% 100%;
    }
    ${tagName} .hero-img-desktop-bg-img {
        position: absolute;
        width: 150%;
        aspect-ratio: 573 / 378;
        background-image: url('/assets/images/hero-desktop-bg.svg');
        background-size: contain;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        transform: scaleX(-1) translateX(-40%) translateY(-10%);
        z-index: 0;
    }
    ${tagName} .hero-content {
        // background: red;
        flex: 4;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding-bottom: 2rem;
        z-index: 1;
    }
    ${tagName} .hero-content > a {
        display: block;
    }
    ${tagName} .hero-title {
        font-family: 'inter-bold';
        font-size: 3rem;
    }
    ${tagName} .hero-text {
        font-size: 0.9em;
        font-family: 'inter-light';
    }
    ${tagName} .hero-text b {
        font-family: 'inter-bold';
    }
    ${tagName} .hero-partner-cont {
        display: flex;
        gap: 0.5em;
    }
    ${tagName} .hero-partner {
        font-size: 0.55em;
        border: 1px solid var(--gray-brand);
        border-radius: 6px;
        padding: 4px 8px;
        transition: 200ms;
    }
    ${tagName} .hero-partner:hover {
        transform: translateY(-2px);
    }
    ${tagName} .hero-partner span {
        margin-bottom: 0.3em;
        display: block;
        text-align: center;
    }
    ${tagName} .hero-partner-logo {
        display: flex;
        width: 100%;
        aspect-ratio: 541 / 151;
        background-position: 50% 50%;
        background-size: contain;
        background-repeat: no-repeat;
    }
    ${tagName} .hero-partner-logo-ihk {
        background-image: url('/assets/images/ihk-akademie-muenchen-oberbayern.svg');
    }
    ${tagName} .hero-partner-logo-product-people {
        background-image: url('/assets/images/product-people-logo.png');
    }
    ${tagName} .hero-partner-logo-ixdf {
        background-image: url('/assets/images/ixdf-logo-black-medium.png');
    }
    ${tagName} .google-review-cont {
        display: flex;
        gap: 1em;
        // max-width: 200px;
        align-items: center;
    }
    ${tagName} .google-review-cont > div {
        // flex: 1;
        height: fit-content;
    }
    ${tagName} .google-review-img-left {
        width: 12em;
        max-width: 180px;
        aspect-ratio: 192 / 43;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: 50% 50%;
        background-image: url('/assets/images-webp/google-review-img-new.webp');
    }
    ${tagName} .google-review-right-side {
        display: flex;
        flex-direction: column;
        gap: 4px;
        font-size: 0.8em;
    }
    ${tagName} .google-review-right-top {
        display: flex;
        gap: 8px;
        justify-content: center;
        align-items: center;
    }
    ${tagName} .google-review-stars {
        display: flex;
        gap: 4px;
    }
    ${tagName} .google-review-star {
        width: 22px;
        aspect-ratio: 1/1;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: 50% 50%;
        background-image: url('/assets/images/star-icon.svg');
    }
    ${tagName} .google-review-rating {
        font-family: 'inter-bold';
    }
    ${tagName} .google-review-right-bottom {
        font-family: 'inter-light';
    }
    @media(max-width: 880px){
        ${tagName} {
            // padding-right: 0rem;
            padding-top: 1rem;
        }
        ${tagName} .hero-img-cont {
            min-width: 320px;
        }
        ${tagName} .hero-img-inner-cont {
            aspect-ratio: 352 / 557;
        }
    }
    @media(max-width: 820px){
        ${tagName} {
            // padding-right: 0rem;
            flex-direction: column-reverse;
            max-width: 520px;
            margin: 0 2rem;
            padding-top: 2rem;
        }
        ${tagName} .hero-content {
            padding-bottom: 1rem;
            gap: 1.2rem;
        }
        ${tagName} .hero-img-cont {
            min-width: initial;
            width: 300px;
            aspect-ratio: 452 / 557;
            display: block;
            flex: initial;
            align-self: initial;
        }
        ${tagName} .hero-img-cont {
            // aspect-ratio: 352 / 557;
            display: none;
        }
        ${tagName} .hero-title {
            line-height: 1em;
            margin-bottom: 0.3em;
        }
        ${tagName} .hero-title span {
            border-bottom: 0.15em solid var(--blue-brand);
        }
    }
    @media(max-width: 460px){
        ${tagName} {
            padding-right: 0;
            margin: 0;
        }
        ${tagName} .hero-img-cont {
            // aspect-ratio: 352 / 557;
            display: block;
            margin: 0 auto;
        }
        ${tagName} .hero-img-desktop-bg-img {
            // aspect-ratio: 352 / 557;
            display: none;
        }
    }
`;

var template = /*html*/`
    <div class="hero-img-cont">
        <div class="hero-img-inner-cont">
            <div class="hero-img-desktop-bg-img"></div>
            <div class="hero-img"></div>
        </div>
    </div>
    <div class="hero-content">
        <div class="hero-title">Wir leben <span>Karriere</span>.</div>
        <div class="hero-text">Mit unseren <b>zertifizierten Weiterbildungen</b> und <b>internen Bewerbungscoachings</b> bringen wir dich deinem Traumjob den entscheidenden Schritt näher. Das Fundament unserer Weiterbildungen bilden dabei unsere <b>qualifizierten Teacher</b>, die <b>echtes und relevantes Wissen aus der Praxis</b> vermitteln. So sorgen wir für eine <b>unvergleichliche digitale Lernatmosphäre</b>.<br><br><b>Starte jetzt mit uns durch</b> und <b>informiere dich zu unseren Weiterbildungen</b>.</div>
        <div class="hero-partner-cont">
            <a href="https://www.ihk-akademie-muenchen.de/" target="_blank" class="hero-partner">
                <span>Offizieller Partner von</span>
                <div class="hero-partner-logo hero-partner-logo-ihk"></div>
            </a>
            <a href="https://www.getproductpeople.com/" target="_blank" class="hero-partner">
                <span>Offizieller Partner von</span>
                <div class="hero-partner-logo hero-partner-logo-product-people"></div>
            </a>
            <a href="https://www.interaction-design.org/courses" target="_blank" class="hero-partner">
                <span>Offizieller Partner von</span>
                <div class="hero-partner-logo hero-partner-logo-ixdf"></div>
            </a>
        </div>
        <div class="google-review-cont">
            <div class="google-review-img-left"></div>
            <div class="google-review-right-side">
                <div class="google-review-right-top">
                    <div class="google-review-stars">
                        <div class="google-review-star"></div>
                        <div class="google-review-star"></div>
                        <div class="google-review-star"></div>
                        <div class="google-review-star"></div>
                        <div class="google-review-star"></div>
                    </div>
                    <div class="google-review-rating">5.0</div>
                </div>
                <div class="google-review-right-bottom">138+ Bewertungen</div>
            </div>
        </div>
        <a cta-prevent-inner-hover target="_blank" href="${config.ctaURLS.main}">${HeroButton(/*html*/`Jetzt bewerben und <span>durchstarten</span>.`)}</a>
    </div>
    <style>
        ${css}
    </style>
`;

class CustomElem extends HTMLElement {
    constructor(options){
        super();
    }

    connectedCallback(){
        this.innerHTML = template;
    }
}

customElements.define(tagName, CustomElem);

export default `<${tagName}></${tagName}>`