import '/services/util.js'

const tagName = "impressum-page";

var pageTitle = "Impressum | Franklin Institute of Applied Sciences";
var pageDescription = "Das Franklin Institute bietet zu 100% staatliche geförderte IT-Weiterbildungen und Karriereunterstützung für Arbeitssuchende. Entdecke deine Möglichkeiten in der IT-Branche mit unserem umfassenden Kursangebot, erfahrenen Dozenten und individueller Beratung. Starte jetzt deine erfolgreiche IT-Karriere mit uns.";

var css = /*css*/`
 ${tagName} h3 {
    line-height: 1.2em;
 }
`;

var template = /*html*/`
    <div class="wrapper">
        <h1>Impressum</h1>
        <h3>
            Pioneer People GmbH<br>
            Messerschmittstr. 4<br>
            80992 München<br><br>
            beratung@franklin-institute.de<br>
            +49 89 62826149<br><br>
            Geschäftsführer: Johannes Burzin, Lorenz Franz<br>
            Ust-ID: DE355563609<br><br>
            Registergericht: Amtsgericht München, HRB 287714<br><br>
            Inhaltlich verantwortlich gem. § 55 RStV:<br>Johannes Burzin (Anschrift s.o.)
        </h3>
    </div>
    <style>${css}</style>
`;

class Page extends HTMLElement {
    constructor(){
        super();
    }

    connectedCallback(){
        util.setPageTitle(pageTitle);
        util.setPageDescription(pageDescription);
        this.innerHTML = template;
    }
}

customElements.define(tagName, Page);

export default `<${tagName}></${tagName}>`