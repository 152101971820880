const tagName = "checkbox-list";

var css = /*css*/`
    ${tagName} {
        display: flex;
        flex-direction: column;
        font-size: 1em;
        gap: 1.2em;
    }
    ${tagName} .checkmark-box {

    }
    ${tagName} .checkmark-title {
        font-family: 'inter-bold';
        font-size: 1.2em;
        margin-bottom: 0.6em;
    }
    ${tagName} .checkmark-title:before {
        display: inline-block;
        content: '';
        width: 1.2em;
        height: 1.2em;
        background-position: 50% 50%;
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url('/assets/images/blue-checkmark.svg');
        transform: translateY(0.2em);
        margin-right: 0.4em;
    }
    ${tagName} .checkmark-text {
        line-height: 1.5em;
        text-align: justify;
    }
`;

const renderInnerHTML = (options)=>{
    var boxesHTML = "";
    options.forEach(box=>{
        boxesHTML += /*html*/`
            <div class="checkmark-box">
                <div class="checkmark-title">${box.title}</div>
                <div class="checkmark-text">${box.content}</div>
            </div>
        `;
    });
    return /*html*/`
        ${boxesHTML}
        <style>${css}</style>
    `;
};

class CustomElem extends HTMLElement {
    constructor(){
        super();
    }

    connectedCallback(){
        var d = window.decodeURIComponent;
        this.options = JSON.parse(d(this.getAttribute('element-options')));
        this.innerHTML = renderInnerHTML(this.options);
        this.removeAttribute('element-options');
    }
}

customElements.define(tagName, CustomElem);

function render(options){
    var e = window.encodeURIComponent;
    return `<${tagName} element-options="${e(JSON.stringify(options))}"></${tagName}>`;
}

export default render