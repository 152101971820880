const tagName = "fcc-6-schritte-component";

var fccComponentCss = /*css*/ `
.fcc-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 60px;
    background-color: transparent;
}

.fcc-svg-container {
    width: 100%;
    max-width: 800px;  
    margin: 0 auto;    
}

svg {
    width: 100%; 
    height: auto;
    overflow: visible;
}

#\\31,
#\\32,
#\\33,
#\\34,
#\\35,
#\\36 { 
    filter: url(#shadow); /* Apply the shadow filter */
    transition: transform 0.3s ease;

}

/* specifically target the IDs starting with numbers by escaping them properly */
/* Escape sequences for IDs starting with numbers */
#\\31:hover,
#\\32:hover { 
    transform: translateX(5px); 
    transition: transform 0.3s ease;
}

#\\33:hover{ 
    transform: translatey(5px); 
    transition: transform 0.3s ease;
}

#\\34:hover,
#\\36:hover{ 
    transform: translatex(-5px); 
    transition: transform 0.3s ease;
}

#\\35:hover{ 
    transform: translatex(-5px); 
    transition: transform 0.3s ease;
}

.centered-text {
    text-align: center;
    margin-bottom: 0.1em;
    font-family: 'inter-bold';
    font-size: clamp(28px, 6.18vw, 60px);
    padding-top: 80px;
    padding-left: 20px;
    padding-right: 20px;
}

.light-blue-text {
    color: var(--blue-brand);
}

`;

var fccComponentTemplate = /*html*/ `
<div class="section-message centered-text">
            <span style="font-size: clamp(28px, 6.18vw, 60px); font-family: 'inter-bold';">
                Franklin Career Center <br>
            </span>
            <span class="section-message centered-text" style="font-size: clamp(28px, 6.18vw, 60px); font-family: 'inter-bold';">
            Unsere
            <span class="light-blue-text" style="font-size: clamp(28px, 6.18vw, 60px); font-family: 'inter-bold';">
                6 Schritte zum Traumjob
            </span>
        </div>
  <section class="fcc-container">
    <div class="fcc-svg-container">
    <svg width="527" height="535" viewBox="0 0 527 535" fill="none" xmlns="http://www.w3.org/2000/svg">
    <!-- Define a filter for the box-shadow effect -->
    <defs>
    <filter id="shadow" x="-50%" y="-50%" width="200%" height="200%">
      <feDropShadow dx="0" dy="4" stdDeviation="6" flood-color="rgba(0, 0, 0, 0.15)" />
    </filter>
  </defs>
    <g id="FCC 6 Schritte">
    <g id="6">
    <path id="Rectangle 1484" d="M146 515C146 526.046 137.046 535 126 535H20C8.95432 535 0 526.046 0 515V404.5V294C0 282.954 8.95432 274 20 274H126C137.046 274 146 282.954 146 294V306.5L119.572 356.101C118.94 357.286 118.946 358.71 119.588 359.891L146 408.5V515Z" fill="#E4E4E4"/>
    <path id="Vector 119" d="M119.5 358.5L146 307H126.5L98 358.5L126 409H146L119.5 358.5Z" fill="#C4C4C4"/>
    <path id="Vector 120" d="M92.9583 358.542L104 337.5H92.9583L81 358.542L92.9583 380H104L92.9583 358.542Z" fill="#C4C4C4"/>
    <text id="Interviewvorbereitung &#38; Feedbackrunde" fill="#5A5A5A" xml:space="preserve" style="white-space: pre" font-family="Inter" font-size="12" font-weight="bold" letter-spacing="0em"><tspan x="6.76953" y="430.864">Interviewvorbereitung </tspan><tspan x="21.1836" y="445.864">&#38; Feedbackrunde</tspan></text>
    <g id="Anmeldung und Einf&#195;&#188;hrung">
    <text id="Anmeldung und Einf&#195;&#188;hrung im Franklin Career Center" fill="#5A5A5A" xml:space="preserve" style="white-space: pre" font-family="Inter" font-size="10" letter-spacing="0em"><tspan x="8" y="470.636">k. Individuelle Vorbereitung </tspan><tspan x="19" y="482.636">auf Intervieweinladungen&#10;</tspan><tspan x="10" y="494.636">l. Nachbereitung von </tspan><tspan x="18" y="506.636">Interviews</tspan></text>
    </g>
    <g id="1662200456">
    <text id="06_2" fill="#5A5A5A" xml:space="preserve" style="white-space: pre" font-family="Inter" font-size="50" font-weight="bold" letter-spacing="0em"><tspan x="6" y="377.682">06</tspan></text>
    </g>
    </g>
    <g id="5">
    <path id="Rectangle 1483" d="M331 306C331 294.954 322.046 286 311 286H187.37C176.325 286 167.37 294.954 167.37 306V328L147.341 355.832C146.839 356.53 146.839 357.47 147.341 358.168L167.37 386V490C167.37 501.046 176.325 510 187.37 510H311C322.046 510 331 501.046 331 490V482L300 435.5L331 389V306Z" fill="#01D5EB"/>
    <path id="Vector 119_2" d="M300.215 435.035L331 482H307.785L277 435.035L307.785 389H331L300.215 435.035Z" fill="#27BFCE"/>
    <path id="Vector 120_2" d="M270.518 435.691L283 455H270.518L257 435.691L270.518 416H283L270.518 435.691Z" fill="#27BFCE"/>
    <g id="03">
    <text id="05_2" fill="white" xml:space="preserve" style="white-space: pre" font-family="Inter" font-size="50" font-weight="bold" letter-spacing="0em"><tspan x="182" y="454.682">05</tspan></text>
    </g>
    <g id="Anmeldung und Einf&#195;&#188;hrung_2">
    <text id="Anmeldung und Einf&#195;&#188;hrung im Franklin Career Center_2" fill="white" xml:space="preserve" style="white-space: pre" font-family="Inter" font-size="10" letter-spacing="0em"><tspan x="178" y="346.636">i. Verbesserung der Online </tspan><tspan x="186" y="358.636">Pr&#xe4;senz unserer Teilnehmer&#10;</tspan><tspan x="178" y="370.636">j. Unterst&#xfc;tzung bei </tspan><tspan x="186" y="382.636">Bewerbungen</tspan></text>
    </g>
    <text id="Online Pr&#195;&#164;senz &#38; Bewerbungsmanagement" fill="white" xml:space="preserve" style="white-space: pre" font-family="Inter" font-size="12" font-weight="bold" letter-spacing="0em"><tspan x="200.609" y="309.864">Online Pr&#xe4;senz &#38; </tspan><tspan x="174.575" y="324.864">Bewerbungsmanagement</tspan></text>
    </g>
    <g id="4">
    <path id="Rectangle 1482" d="M527 514C527 525.046 518.046 534 507 534H367.704C356.658 534 347.704 525.046 347.704 514V464L327.819 435.187C327.33 434.478 327.349 433.536 327.867 432.848L347.704 406.5V293C347.704 281.954 356.658 273 367.704 273H389L439.5 299.5L491 273H507C518.046 273 527 281.954 527 293V514Z" fill="#E4E4E4"/>
    <path id="Vector 119_3" d="M439.5 299.5L491 273V292.5L439.5 321L389 293V273L439.5 299.5Z" fill="#C4C4C4"/>
    <path id="Vector 119_4" d="M440.458 329.042L461.5 318V329.042L440.458 341L419 329.042V318L440.458 329.042Z" fill="#C4C4C4"/>
    <text id="Dein pers&#195;&#182;nliches Karrierecoaching" fill="#5A5A5A" xml:space="preserve" style="white-space: pre" font-family="Inter" font-size="12" font-weight="bold" letter-spacing="0em"><tspan x="386.684" y="409.864">Dein pers&#xf6;nliches </tspan><tspan x="388.752" y="424.864">Karrierecoaching</tspan></text>
    <g id="Anmeldung und Einf&#195;&#188;hrung_3">
    <text id="Anmeldung und Einf&#195;&#188;hrung im Franklin Career Center_3" fill="#5A5A5A" xml:space="preserve" style="white-space: pre" font-family="Inter" font-size="10" letter-spacing="0em"><tspan x="367" y="448.636">f. Individuelles Coaching mir </tspan><tspan x="376" y="460.636">unseren ausgebildeten </tspan><tspan x="376" y="472.636">systematischen Coaches&#10;</tspan><tspan x="365" y="484.636">g. Karriereplanung&#10;</tspan><tspan x="365" y="496.636">h. Betreuung der Teilnehmer </tspan><tspan x="376" y="508.636">&#xfc;ber den gesamten Verlauf </tspan><tspan x="376" y="520.636">des FCCs</tspan></text>
    </g>
    <g id="1662200455">
    <text id="04_2" fill="#5A5A5A" xml:space="preserve" style="white-space: pre" font-family="Inter" font-size="50" font-weight="bold" letter-spacing="0em"><tspan x="405.345" y="389.682">04</tspan></text>
    </g>
    </g>
    <g id="3">
    <path id="Rectangle 1481" d="M346 41C346 29.9543 354.954 21 366 21H506C517.046 21 526 29.9543 526 41V54.9947V110.181V231.457C526 242.503 517.046 251.457 506 251.457H471L440.027 269.405C439.394 269.772 438.611 269.763 437.986 269.383L408.5 251.457H366C354.954 251.457 346 242.503 346 231.457V223L380 177L346 133V41Z" fill="#01D5EB"/>
    <path id="Vector 117" d="M372.5 177.559L346 133H365.5L394 177.559L365.5 223H346L372.5 177.559Z" fill="#27BFCE"/>
    <path id="Vector 118" d="M399.042 177.319L388 159H399.042L411 177.319L399.042 196H388L399.042 177.319Z" fill="#27BFCE"/>
    <g id="02">
    <text id="03_2" fill="white" xml:space="preserve" style="white-space: pre" font-family="Inter" font-size="50" font-weight="bold" letter-spacing="0em"><tspan x="431" y="197.682">03</tspan></text>
    </g>
    <g id="Anmeldung und Einf&#195;&#188;hrung_4">
    <text id="Anmeldung und Einf&#195;&#188;hrung im Franklin Career Center_4" fill="white" xml:space="preserve" style="white-space: pre" font-family="Inter" font-size="10" letter-spacing="0em"><tspan x="355" y="75.6364">c. Erstellung eines professionellen </tspan><tspan x="367" y="87.6364">Lebenslaufs&#10;</tspan><tspan x="355" y="99.6364">d. Bewerbungsbilderstellung&#10;</tspan><tspan x="355" y="111.636">e. Erstellung einer professionellen </tspan><tspan x="367" y="123.636">Bewerbungsmappe&#10;</tspan></text>
    </g>
    <text id="Professioneller Au&#195;&#159;enauftritt" fill="white" xml:space="preserve" style="white-space: pre" font-family="Inter" font-size="12" font-weight="bold" letter-spacing="0em"><tspan x="391.168" y="40.8636">Professioneller </tspan><tspan x="395.363" y="55.8636">Au&#xdf;enauftritt&#10;</tspan></text>
    </g>
    <g id="2">
    <path id="Rectangle 1478" d="M173 245C173 256.046 181.954 265 193 265H299.64C310.686 265 319.64 256.046 319.64 245V208L341.058 180.221C341.613 179.502 341.613 178.498 341.058 177.779L319.64 150V20C319.64 8.9543 310.686 0 299.64 0H193C181.954 0 173 8.9543 173 20V32.9981L200.56 85.2874L173 136.561V245Z" fill="#E4E4E4"/>
    <text id="Anforderungen &#38; Ziele des FCCs" fill="#5A5A5A" xml:space="preserve" style="white-space: pre" font-family="Inter" font-size="12" font-weight="bold" letter-spacing="0em"><tspan x="194.232" y="163.864">Anforderungen &#38; </tspan><tspan x="200.607" y="178.864">Ziele des FCCs</tspan></text>
    <path id="Vector 117_2" d="M199.5 85.2893L173 33H192.5L221 85.2893L193 136.563H173L199.5 85.2893Z" fill="#C4C4C4"/>
    <path id="Vector 118_2" d="M226.042 85.329L215 63.9648H226.042L238 85.329L226.042 107.116H215L226.042 85.329Z" fill="#C4C4C4"/>
    <g id="Anmeldung und Einf&#195;&#188;hrung_5">
    <text id="Anmeldung und Einf&#195;&#188;hrung im Franklin Career Center_5" fill="#5A5A5A" xml:space="preserve" style="white-space: pre" font-family="Inter" font-size="10" letter-spacing="0em"><tspan x="189" y="199.636">b. Ausarbeitung der </tspan><tspan x="200" y="211.636">beruflichen W&#xfc;nsche </tspan><tspan x="200" y="223.636">und Ziele der </tspan><tspan x="200" y="235.636">Teilnehmer</tspan></text>
    </g>
    <g id="2_2">
    <text id="02_2" fill="#5A5A5A" xml:space="preserve" style="white-space: pre" font-family="Inter" font-size="50" font-weight="bold" letter-spacing="0em"><tspan x="247" y="104.682">02</tspan></text>
    </g>
    </g>
    <g id="1">
    <path id="Rectangle 1477" d="M0 24C0 12.9543 8.9543 4 20 4H126.64C137.686 4 146.64 12.9543 146.64 24V56L168.048 83.2865C168.609 84.0016 168.617 85.0049 168.068 85.7292L146.64 114V245C146.64 256.046 137.686 265 126.64 265H20C8.95429 265 0 256.046 0 245V232.5L27.56 181L0 130.5V24Z" fill="#01D5EB"/>
    <path id="Vector 115" d="M27.56 181L0 130.5H20.28L49.92 181L20.28 232.5H0L27.56 181Z" fill="#27BFCE"/>
    <path id="Vector 116" d="M55.163 181.042L43.6797 160H55.163L67.5997 181.042L55.163 202.5H43.6797L55.163 181.042Z" fill="#27BFCE"/>
    <g id="3_2">
    <text id="01_2" fill="white" xml:space="preserve" style="white-space: pre" font-family="Inter" font-size="50" font-weight="bold" letter-spacing="0em"><tspan x="76.9609" y="200.682">01</tspan></text>
    </g>
    <text id="Willkommen zum Franklin Career Center" fill="white" xml:space="preserve" style="white-space: pre" font-family="Inter" font-size="12" font-weight="bold" letter-spacing="0em"><tspan x="24.9601" y="28.8636">Willkommen zum </tspan><tspan x="30.5675" y="43.8636">Franklin Career </tspan><tspan x="55.2355" y="58.8636">Center</tspan></text>
    <text id="Anmeldung und Einf&#195;&#188;hrung im Franklin Career Center_6" fill="white" xml:space="preserve" style="white-space: pre" font-family="Inter" font-size="10" letter-spacing="0em"><tspan x="15" y="79.6364">a. Anmeldung und </tspan><tspan x="25.3984" y="91.6364">Einf&#xfc;hrung im </tspan><tspan x="25.3984" y="103.636">Franklin Career </tspan><tspan x="25.3984" y="115.636">Center</tspan></text>
    </g>
    </g>
    </svg>
    </div>
  </section>
`;

class FCC6SchritteComponent extends HTMLElement {
    constructor() {
      super();
    }
  
    connectedCallback() {
      const shadow = this.attachShadow({ mode: "open" });
      const container = document.createElement("div");
      container.innerHTML = fccComponentTemplate;
  
      const style = document.createElement("style");
      style.textContent = fccComponentCss;
  
      shadow.appendChild(style);
      shadow.appendChild(container);
    }
  }
  
  customElements.define(tagName, FCC6SchritteComponent);
  
  export default FCC6SchritteComponent;
